<template>
  <div id="app">
    <!-- <img src="./assets/logo.png"> -->
    
    <router-view/>

  </div>
</template>

<script>
export default {
    Footame: 'App'
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
#app {
  
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  overflow: hidden;
  /* background: #F1F2F6; */
  /* margin-top: 60px; */
}
</style>
