import Vue from 'vue'
import Router from 'vue-router'
// import HelloWorld from '@/components/HelloWorld'
import content from '@/components/content'
import index from '@/view/index/index'
import jinFu from '@/view/details/jinFu'
import financialDetails from '@/view/details/financialDetails'
import nonFinancial from '@/view/details/nonFinancial'
import solutionDetails from '@/view/details/solutionDetails'
import product from '@/view/details/product'

// import financialInnovation from '@/view/details/financialInnovation'
// import generalSolution from '@/view/details/generalSolution'
// import fProductDetails from '@/view/details/fProductDetails'
// import gDeteils from '@/view/details/gDeteils'
import sassPlatform from '@/view/details/sassPlatform'
// import sassDetails from '@/view/details/sassDetails'
import aboutAs from '@/view/details/aboutAs'
import pay from '@/view/details/pay'
import jspay from '@/view/details/jspay'



Vue.use(Router)

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
return routerPush.call(this, location).catch(error=> error)
}
export default new Router({
  routes: [
    {
      path:'/',
      name:'/',
      redirect: '/index'
    },
    {
      path:'/jspay',
      name:'jspay',
      component:jspay
    }
    ,
    {
      path:'/pay',
      name:'pay',
      component:pay
    },
    {
      path: '/index',
      // name: 'HelloWorld',
      component: content,
      children:[
        {
          path:'/',
          name:'index',
          component:index,
        },
        {
          path:'/index/solutionDetails/details',
          name:'solutionDetails',
          component:solutionDetails,
        },
        
        {
          path:'/jinFu',
          name:'solutionDetail',
          component:jinFu
        },
        
        {

          path:'/jinFu/financialDetails/details',

          name:'financialDetails',
          component:financialDetails
        },
        {
          path:'/jinFu/nonFinancial/details',
          name:'nonFinancial',
          component:nonFinancial
        },
        {
          path:'/jinFu/product/details',
          name:'product',
          component:product
        },
        {
          path:'/financialInnovation/sassPlatform',
          name:'sassPlatform',
          component:sassPlatform
        },
        {
          path:'/financialInnovation/solutionDetails/details',
          name:'solutionDetails',
          component:solutionDetails
        },
        {
          path:'/generalSolution/sassPlatform',
          name:'sassPlatform',
          component:sassPlatform
        },
        {
          path:'/generalSolution/solutionDetails/details',
          name:'solutionDetails',
          component:solutionDetails
        },
        {
          path:'/sassPlatform/sassPlatform',
          name:'sassPlatform',
          component:sassPlatform
        },
        {
          path:'/sassPlatform/solutionDetails/details',
          name:'solutionDetails',
          component:solutionDetails
        },
        {
          path:'/aboutAs',
          name:'aboutAs',
          component:aboutAs
        },
      ]
    }
    
  ]
})
