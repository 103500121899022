<template>
    <div>
      <div class="header">
        <div class="header-ui">
          <img src="@/assets/img/log.png" alt="">
        </div>
        <div class="header-menus">
          <img class="img-menus" @click="clickMenus" src="@/assets/img/menus.png" alt="">
          <ul v-show="isMenus" class="menus-nav">
            <li v-for="(item, index) in navList" :class="clickLi==item.path?'header-Li choosezli' : 'header-Li'" @click="chooseLi(index,item)" :key="index">{{ item.name }}</li>
          </ul>
        </div>
        <ul class="header-nav">
          <li v-for="(item, index) in navList" :class="clickLi==item.path?'header-Li choosezli' : 'header-Li'" @click="chooseLi(index,item)" :key="index">{{ item.name }}</li>
        </ul>
        <div class="login-but" >
          <div>
            <img v-if="isImg" class="login-img" :src="img" alt="">
          </div>
          <button @click="login" v-if="islogin" class="header-log0 header-login">{{user}}</button>
        </div>
      </div>
      <!-- <div class="header2" v-if="isDetails">
        <div class="header-ui">
          <img src="../../static/img/logo2.png" alt="">
        </div>
        <div class="header-menus">
          <img class="img-menus" @click="clickMenus" src="../assets/menus.png" alt="">
          <ul v-show="isMenus" class="menus-nav">
            <li v-for="(item, index) in navList" :class="clickLi==item.path?'header-Li choosezli' : 'header-Li'" @click="chooseLi(index,item)" :key="index">{{ item.name }}</li>
          </ul>
        </div>
        <ul class="header-nav">
          <li v-for="(item, index) in navList" :class="clickLi==item.path?'header-Li header-Li2 choosezli choosezli2' : 'header-Li header-Li2'" @click="chooseLi(index,item)" :key="index">{{ item.name }}</li>
        </ul>
        <div style="display:flex">
          <div>
            <img v-if="isImg" class="login-img" :src="img" alt="">
          </div>
          <button @click="login" class="header-login">{{user}}</button>
        </div>


      </div> -->

    </div>

</template>

>
<script>
export default {
    data(){
        return{

           Wechat_authorized_login:false,
          obj:{},

          // isIndex:true,
          islogin:true,
          isDetails:false,
          clickLi:'',
          user:'登录',
          img:'',
          isImg:false,
          setTimeoutData:null,
          navList: [
            { name: "首页",path:'/index' },
            // { name: "金服产品",path:'/jinFu' },
            { name: "金融创新方案",path:'/financialInnovation/sassPlatform',type:1 },
            { name: "通用解决方案",path:'/generalSolution/sassPlatform' ,type:2},
            { name: "SAAS技术平台" ,path:'/sassPlatform/sassPlatform',type:3},
            { name: "关于我们",path:'/aboutAs' },
          ],
          isMenus:false
        }
    },
    mounted(){
      this.getRoute()
      document.addEventListener("click",(e)=>{
        let className=e.target.className
        if(className!='menus-nav' && className!='img-menus'){

          this.isMenus=false
        }
      })
    },
    watch:{
      $route(){
        this.getRoute()
      }
    },
    created(){
    this.setToken()
     if(this._isMobile()){
          if(!this.isWenXin()){
            this.islogin=false
          }}
    // this.shixoa()
    },
    methods:{
      clickMenus(){
        this.isMenus=!this.isMenus
      },
      shixoa(){
        // if(this.setTimeoutData){
        //   clearTimeout(this.setTimeoutData)
        //   this.setTimeoutData=null
        // }
        // this.setTimeoutData=setTimeout(function(){
        //   this.isImg=false
        //   this.user="登录"
        //   alert("token 已过期")
        // },100000)
      },
      GetToken(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
      },
      setToken(){
        if(this.GetToken("token") !=null && this.GetToken("token").toString().length>1){

          // sessionStorage.setItem('token', this.GetToken("token"));
          let token=this.GetToken("token")
          console.log(token)
          this.$http.get(this.$api.getToken+token).then(res=>{
            
            if(res.code==20000){
            this.isImg=true
              sessionStorage.setItem('headimgurl', res.data.headimgurl);
              sessionStorage.setItem('nickname', res.data.nickname);
               sessionStorage.setItem('openid', res.data.openid)
              let nickname=(sessionStorage.getItem('nickname'))
              let headimgurl=(sessionStorage.getItem('headimgurl'))
              let userId=(sessionStorage.getItem('userId'))
              this.img=headimgurl
              this.user=nickname
              console.log(nickname,userId)
              console.log(res)
            }
            else{
              this.user="登录"
              this.isImg=false
            }
          })
        }else{
          
          return false
        }
      },
      login(){
        if(this._isMobile()){
          if(this.isWenXin()){
             window.location.href=this.$api.wxlogin
          }else{

            alert("暂未开放")
          }
        }else{
          console.log(this.$api.loginUrl)
          window.location.href=this.$api.loginUrl
        }
        // window.location.href=this.$api.loginUrl
      },
   _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
},
 isWenXin: function () {
      if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
        return true; // 是微信端
      } else {
        return false;
      }

 },
      getRoute(){
        // this.clickLi=x.path
        let pathName=this.$route.path
        this.isDetails=false
        this.isIndex=true
        this.navList.map(x=>{
          if(pathName.indexOf(x.path)>=0){
            this.clickLi=x.path
          }
          if(pathName.indexOf('details')>=0){
            this.isDetails=true
            this.isIndex=false
          }
        })
      },
      chooseLi(i,item){
        this.clickLi=item.path
        this.$router.push({path:item.path,query: { name: item.name,type:item.type || null },})
        this.isMenus=false
        console.log(i);
      }

}}
</script>
<style scoped>
@media screen and (min-width: 750px) and (max-width: 1920px) {
  .header {
    width: 1190px;
    height: 150px;
    display: flex;
    position: absolute;
    justify-content: space-around;
    margin: auto;
    left: 50%;
    transform: translatex(-50%);
  }
  .login-but{
    display: flex;
  }
  .header-ui{
    width: 200px;
    height: 52px;
    margin-top: 49px;
  }
  .header-menus{
    display: none;
  }
  .header-ui img{
    width: 100%;
    /* height: 52px; */
  }
  .header-nav {
    margin-top: 65px;
    list-style: none;
    cursor: pointer;
  }
  .header-Li{
    float: left;
    margin-left: 50px;
    /* padding: 10px; */
    padding-bottom: 10px;
    font-size: 16px;
    color: #fff;
    
  }
  .header-log0{
    color: #fff;
  }
  .header-login{
    margin-left: 5px;
    /* margin-top: 10px; */
    cursor: pointer;
    /* color: #fff; */
    background: transparent;
    border: none;
    font-size: 16px;
    /* margin-left: 50px; */
  }
  .login-img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 65px;
  }
  .choosezli {
    border-bottom: 3px solid;
    font-weight: bold;
  }
  .header2{
    width: 1190px;
    height: 150px;
    display: flex;
    justify-content: space-around;
    color: #424242;
    background: #fff;
  }
  .choosezli2{
    border-bottom: 3px solid #FF9A00;
    /* color: #FF9A00; */
  }
  .header-Li2{
    color: #424242;
  }
}
@media screen and (min-width: 10px) and (max-width: 750px) {
  .header {
    width: 100%;
    height: 70px;
    display: flex;
    position: absolute;
    justify-content: space-between;
    margin: auto;
    /* left: 50%;
    transform: translatex(-50%); */
  }
  .login-but{
    display: flex;
    /* margin-top: -106px; */
    /* margin-left: 275px; */
  }
  .header-ui{
    /* width: 110px;
    height: 52px;
    margin-top: 40px;
    margin-left: 15px; */
    display: none;
  }
  .header-menus{
    width: 50%;
    height: 40px;
    margin-top: 15px;
    margin-left: 15px;
    position: relative;
  }
  .img-menus{
    width: 20%;
  }
  .menus-nav{
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 55px;
    border-radius: 10px;
    list-style: none;
    background: #313a56e0;
  }
  .header-ui img{
    width: 100%;
    /* height: 52px; */
  }
  .header-nav {
    display: none;
  }
  .header-Li{
    /* float: left;
    margin-left: 50px; */
    padding: 15px;
    padding-bottom: 10px;
    font-size: 15px;
    color: #fff;
    
  }
  .header-log0{
    color: #ff9a00;
  }
  .header-login{
    /* margin-left: 5px; */
    /* margin-top: 10px; */
    padding-right: 15px;
    cursor: pointer;
    /* color: #fff; */
    background: transparent;
    border: none;
    font-size: 20px;
    /* margin-left: 50px; */
  }
  .login-img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 20px;
    margin-right: 10px;
  }
  .choosezli {
    font-weight: bold;
    color: #cc7811;
  }
  .header2{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    color: #424242;
    background: #fff;
    position: fixed;
    z-index: 99;
  }
  .choosezli2{
    border-bottom: 3px solid #FF9A00;
    /* color: #FF9A00; */
  }
  .header-Li2{
    color: #424242;
  }
}
</style>