<template>
<div class="demo-bg">

  <div class="demo-sty">
    <div>
      <input type="text" v-model="name" placeholder="请输入姓名" name="name" />
    </div>
    <div>
      <input
        type="text"
        v-model="phone"
        placeholder="请输入电话"
        name="phone"
      />
    </div>
    <div>
      <textarea
        name="content"
        v-model="message"
        rows="6"
        cols="40"
        placeholder="请输入留言内容"
        onpropertychange="if(this.scrollHeight>80) this.style.posHeight=this.scrollHeight+5"
      ></textarea>
    </div>
    <el-upload
  class="upload-demo"
  action="/gateway-api/file/file/uploadFile"

  :on-success="UploadSuccess"
  :on-preview="handlePreview"
  :on-remove="handleRemove"

  :data="ma"
  :limit="1"
   drag
  list-type="picture">
   <i class="el-icon-upload"></i>
    <div class="el-upload__text">上传图片</div>
</el-upload>
    <div>
        <button @click="noSubmit" class="but-sty">取消</button>
        <button @click="submit" class="but-sty">提交</button>
    </div>
  </div>
</div>
</template>
<script>
export default {
  props:['productId'],
  data() {
    return {
      name:'',
      phone:'',
      message:'',
      fileList:[],
      imgurl:'',
      fileid:'',
      ma: { bucketName: 'messageImg' },
    };
  },
  methods: {
     isWenXin: function () {
      if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
        return true; // 是微信端
      } else {
        return false;
      }

 },
    submit() {
         
      if(sessionStorage.getItem("openid")!=null){
      let data = {
        name: this.name,
        message: this.message,
        phone: Number(this.phone),
        productId:this.productId,
        userId:sessionStorage.getItem('userId'),
        imgurl:this.imgId==''?null:this.imgId,
        
        fileid:this.fileid==''?null:this.fileid,
      };
      let reg_tel= /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
     if(this.phone=='' || this.phone==null){
       this.$toast('请输入手机号')
     }else if(!reg_tel.test(this.phone)){
        this.$toast('请输入正确手机号')
     }else if(this.name=='' || this.name==null){
        this.$toast('请输入姓名')
     }else if(this.message=='' || this.message==null){
        this.$toast('请输入咨询内容')
     }else{
         this.$http.post(this.$api.postMessage, data).then((res) => {
          this.$toast(res.msg)
          this.$parent.chengDemo()
      });
        let yy = new Date().getFullYear();
    　　let mm = new Date().getMonth()+1;
    　　let dd = new Date().getDate();
    　　let hh = new Date().getHours();
    　　let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
    　　let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
       let d={
          name:this.name,
          openid:sessionStorage.getItem("openid"),
          scene:this.isWenXin()?1:2,
          // openid:"o59kX619xOOzJ2Bwd4YqlY5gjO50",
          datetime:yy+'/'+mm+'/'+dd+' '+hh+':'+mf+':'+ss,
          message:'感谢你的咨询，我们将尽快联系你'
        }
        this.$http.postDataForm3(this.$api.noticemessage,d).then(res=>{

        })
     }
      }else{
        this.$toast("请先扫码登录")
          this.$parent.chengDemo()
      }

    },
    UploadSuccess(response){
      this.fileid=response.data.id
      this.imgurl=response.data.fileUrl
    },

    noSubmit(){
        this.$parent.chengDemo()
    }
  },
  handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      }

};
</script>
<style scoped>
.demo-bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0000,0000,0000,0.5);
}
.demo-sty{
    width: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    padding: 55px;
    border-radius: 10px;
    background: #fff;
}
input:focus , textarea:focus{
    outline: medium;
}
input,textarea{
    width: 80%;
    margin: 10px auto;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #cbcaca;
}
.but-sty{
    border: none;
    background: #4D5579;
    color: #FFF;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 0 10px;
    text-align: end;
}
.msg-sty{
    position: absolute;
    top: 50%;
    left: 50%;
    background: #12326d6b;
    color: #Fff;
    padding: 10px 25px;
}
</style>
