<template>
  <div>

    <div class="header-bg">
      <div class="name" >金服产品</div>
    </div>
     <div v-for="(item,index) in productList" :key="index"  class="common-class">
        <div class="common financial">
          <img src="" alt="" style="display:block">
          <div class="financial-title">{{item.name}}</div>
        </div>
        <div :class="(index1 + 1) % 4==0?'class-sty sty-noright':'class-sty'" v-for="(item1,index1) in item.value" :key="index1">
          <div @click.prevent="goFinancial(item1)" >
            <img class="class-img" :src="item1.thumbnail" alt="">
            <div class="class-tit" >{{item1.name}}</div>
            <div class="class-con" v-html="item1.digest"></div>
          </div>
           <!-- <div class="consult" @click.prevent="checkID(item1.id)">立即咨询</div> -->
        </div>
      </div>
      <messageDemo :productId="productId" v-show="isDemo"></messageDemo>
    <!-- <div style="
      font-size: 100px;
    padding: 60px;
    color: #afa8a8;
    text-align: center;">敬请期待~~~</div> -->
  <!--
      <div class="common financial">
        <img src="" alt="" style="display:block">
        <div class="financial-title">金融产品</div>
      </div>
    <div class="common-class">
      <div @click="goFinancial(item.id)" :class="(index + 1) % 4==0?'class-sty sty-noright':'class-sty'" v-for="(item,index) in classList" :key="index">
        <img class="class-img" :src="item.thumbnail" alt="">
        <div class="class-tit" >{{item.name}}</div>
        <div class="class-con">{{item.digest}}</div>
      </div>
    </div>
    <div class="common financial">
        <img src="" alt="" style="display:block">
        <div class="financial-title">非金融产品</div>
      </div>
    <div class="common-class">
      <div @click="goNoFinancial(item)" :class="(index + 1) % 4==0?'class-sty sty-noright':'class-sty'" v-for="(item,index) in noClassList" :key="index">
        <img class="class-img" :src="item.img" alt="">
        <div class="class-tit">{{item.title}}</div>
        <div class="class-con class-con-bot">{{item.content}}</div>
      </div>
    </div> -->
  </div>
</template>
<script>
import messageDemo from './messageDemo.vue';
export default {
  data() {
    return {
      allProduct:{},
      classList:[ ],
      noClassList:[
        {img:require('@/assets/img/core4.jpg'),title:'理财产品',content:'提供针对性理财服务'},
        {img:require('@/assets/img/core4.jpg'),title:'理财产品',content:'提供针对性理财服务'},
        {img:require('@/assets/img/core4.jpg'),title:'理财产品',content:'提供针对性理财服务'}
      ],
      productList:{},
      productId:'',
      isDemo:false
    };
  },
  mounted(){
    this.getProductList()
  },
  methods:{
    chengDemo(){
      this.isDemo=false
    },
    checkID(id){
      this.productId=id
      this.isDemo=true
    },
    getProductList(){
      this.$http.get(this.$api.getProdectList).then(res=>{
        // console.log(res);
        this.productList=res
        console.log(res.value);
      })
    },
    // getProduct(){
    //   this.$http.get(this.$api.getAllProduct).then(res=>{
    //     console.log(res);
    //     if(res.code==20000){
    //       this.classList=res.data
    //       this.allProduct=res.data
    //       // this.$router.push('/jinFu/financial/details')
    //     }
    //   })
    // },
    goFinancial(item1){
      console.log(item1)
     
      if(item1.externalLink.length==0)
      {
      this.$router.push({path:'/jinFu/product/details',query:{
        id:item1.id
      }})
      console.log(id);
      // console.log(index);
    }else{
      
      window.location.href=item1.externalLink
    }

    },
    goNoFinancial(){
      this.$router.push('/jinFu/nonFinancial/details')
    }
  },
  components: {
    messageDemo
  },
};
</script>
<style scoped>
@media screen and (min-width: 750px) and (max-width: 1920px) {
  .header-bg {
    width: 100%;
    height: 600px;
    background: url("../../assets/img/bg.png") no-repeat 100%;
    background-size: 100% 100%;
    /* background-size: 100%; */
    /* background-color: antiquewhite; */
  }
  .name{
      line-height: 79px;
      margin: auto;
      padding-top: 265px;
      align-items: center;
      font-size: 60px;
      text-align: center;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #FFFFFF;
  }
  .common{
      width: 1190px;
      height: 300px;
      margin:auto;
      background: linear-gradient(270deg, rgba(79, 86, 123, 0.08) 0%, #4D5579 100%);
  }
  .financial-title{
      height: 300px;
      font-size: 40px;
      margin-left: 120px;
      line-height: 300px;
      text-align: left;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
  }
  .common-class{
    width: 1190px;
    margin:100px auto;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    /* border: 1px solid; */
  }
  .consult{
    padding: 5px 7px;
    position: absolute;
    /* bottom: 0; */
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    color: #fff;
    background: #4D5579;
  }
  .class-sty{
   width: 265px;
    /* height: 225px; */
    margin-top: 100px;
    margin-right: 40px;
    cursor: pointer;
    position: relative;
    /* margin-right: 15px; 手机尺寸*/
    /* padding: 0 45px; */
  }
  .sty-noright{
  margin-right:0;
  }
  .class-img{
    width: 260px;
    height: 260px;
  }
  .class-tit{
    height: 80px;
    font-size: 20px;
    margin-top: 40px;
    color: #424242;
  }
  .class-tit:hover , .class-con:hover{
   color: #36499b;
  }
  .class-con{
    height: 115px;
    margin: 10px 0;
    text-align: left;
    /* white-space: nowrap;
    overflow: hidden; */
    text-overflow: ellipsis;
    color: #949494;
    word-wrap: break-word;
    font-size: 16px;

  }
  .class-con-bot{
    margin-bottom: 105px;
  }
}
@media screen and (min-width: 10px) and (max-width: 750px) {
  .header-bg {
    width: 100%;
    height: 300px;
    background: url("../../assets/img/bg.png") no-repeat 100%;
    background-size: 100% 100%;
    /* background-size: 100%; */
    /* background-color: antiquewhite; */
  }
  .name{
      line-height: 79px;
      margin: auto;
      padding-top: 140px;
      align-items: center;
      font-size: 35px;
      text-align: center;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #FFFFFF;
  }
  .common{
      width: 100%;
      height: 110px;
      margin:auto;
      background: linear-gradient(270deg, rgba(79, 86, 123, 0.08) 0%, #4D5579 100%);
  }
  .financial-title{
      height: 110px;
      font-size: 30px;
      margin-left: 10%;
      line-height: 110px;
      text-align: left;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
  }
  .common-class{
    width: 95%;
    margin: 50px auto;
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    text-align: center;
    /* border: 1px solid; */
  }
  .class-sty{
    width: 43%;
    /* height: 220px; */
    margin-top: 50px;
    position: relative;
    cursor: pointer;
    /* margin-right: 120px; */
    margin-right: 7%; 
    /* padding: 0 45px; */
  }
   .consult{
    padding: 5px 7px;
    position: absolute;
    /* bottom: 0; */
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    color: #fff;
    background: #4D5579;
  }
  .sty-noright{
  margin-right:0;
  }
  .class-img{
    width: 125px;
    height: 125px;
  }
  .class-tit{
    height: 110px;
    margin-top: 40px;
    font-size: 20px;
    color: #424242;
  }
  .class-con{
    height: 115px;
    margin: 10px 0;
    /* white-space: nowrap;
    overflow: hidden; */
    text-align: left;
    text-overflow: ellipsis;
    color: #949494;
    word-wrap: break-word;
    font-size: 16px;

  }
  .class-con-bot{
    margin-bottom: 105px;
  }
}

</style>
