<template>
    <div>
        <button style="background:rgba(255, 192, 203, 0);border:2px solid rgba(255, 192, 203, 0);position:fixed; right:0px; bottom:0px;" @click="handleClick"><img src="../assets/img/consult.png" style="width:90px;height:80px;" alt="" /></button>
        <div v-if="showok" style="position:fixed;left:20px;bottom:5px;z-index:2;">
        <div class="mai"></div>
        <div class="lyb">留言板
        <!-- <div>留言板</div>
        <div><button @click="soso" class="sx">—</button></div> -->
        </div>
        <div style="background:white;width:240px;padding:60px 0px;">
          <p><img src="../assets/img/ok.png" style="width:100px;height:100px;margin-left:70px;" alt="" /></p>
          <p style="margin-left:80px;">感谢留言</p>
          <p style="margin-left:40px;">我们会尽快与您联系</p>
          <p><button class="delet" @click="clics">关闭</button></p>
        </div>
        <!-- </div> -->
      </div>
      <div v-if="sho" style="position:fixed;left:20px;bottom:5px;z-index:1;">
    <div class="ma">
        <div class="ly">留言板<button class="s" @click="handleci"><img src="../assets/img/squ.png" style="width:12px;height:12px;" alt="" /></button></div>
    </div>
        <table style="border-top:10px solid white;border-left: 5px solid rgba(255, 192, 203, 0);border-right: 5px solid rgba(255, 192, 203, 0);width:240px;" >
        </table>
    </div>
    <div v-if="sh" class="shea">
    <div class="ma">
        <div class="ly">留言板<button class="s" @click="cli">—</button><button class="ssx" @click="clicss"><img src="../assets/img/error.png" style="width:12px;height:12px;" alt="" /></button></div>
    </div>
        <p style="background:white;">
        <table align="center" style="width:240px;background:white;padding:12px 0px;" >
          <tr><textarea v-model="content" style="height:80px;width:218px;resize:none;border-radius: 4px;margin-left:8px;" placeholder="请在此输入留言内容，我们会尽快与您联系。"></textarea></tr>
          <tr><input type="text" v-model="name" placeholder="姓名（必填）" style="height:32px;width:218px;border-radius: 4px;margin-left:8px;"></tr>
          <tr style="padding-left: 8px;"><span style="color:#F56C6C;font-size:12px;line-height:1;padding-top:4px;text-align:left;">{{errorTextName}}</span></tr>
          <tr><input type="text" v-model="phoneNo" placeholder="电话（必填）" style="height:32px;width:218px;border-radius: 4px;margin-left:8px;"></tr>
          <tr style="padding-left: 8px;"><span style="color:#F56C6C;font-size:12px;line-height:1;padding-top:4px;text-align:left;">{{errorText}}</span></tr>
          <!-- <tr ><span style="color:#F56C6C;font-size:12px;line-height:1;padding-top:4px;text-align:left;">{{phoneNoErrors.errorText}}</span></tr> -->
          <tr><input type="text" v-model="email" placeholder="邮箱" style="height:32px;width:218px;border-radius: 4px;margin-left:8px;"></tr>
          <tr><input type="text" v-model="addressInfo" placeholder="地址" style="height:32px;width:218px;border-radius: 4px;margin-left:8px;"></tr>
        </table>
        <!-- <div class="po"> -->
          <button class="po" type="primary" @click="handlepost">发送</button>
        <!-- </div> -->
      </p>
    </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
        content:'',
        name:'',
        phoneNo:'',
        email:'',
        addressInfo:'',
        createTime:'',
        createBy:'',
        delFlag:'',
        id:'',
        status:'',
        updateBy:'',
        updateTime:'',
        errorText:'',
        errorTextName:'',
        sho:false,
        sh:false,
        showok:false,
        }
    },
    methods: {
    handleClick(){
      this.showok=false,
                this.sho=false,
                this.sh=true
            },
    handlecl(){
      this.showok=false,
      this.sho=true
    },
    clicss(){
      this.sho=false,
      this.sh=false,
      this.showok=false
    },
    clics(){
      this.sho=false,
      this.sh=false,
      this.showok=false,
      this.$router.go(0);
      // this.showo=false
    },
    handleci(){
      this.sh=true,
      this.sho=false
    },
    cli(){
      this.sh=false,
      this.sho=true
    },
     isWenXin: function () {
      if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
        return true; // 是微信端
      } else {
        return false;
      }

 },
      handlepost(){
      if (this.phoneNo==''||this.phoneNo==undefined||this.phoneNo==null) {
        this.errorText='请输入您的手机号',
        this.errorTextName=''
        if(this.name==''||this.name==undefined||this.name==null){
          this.errorText='',
          this.errorTextName='请输入您的姓名'
        }
        // this.errorTextName='请输入您的姓名'
      //  errorText = '您输入的电话不符合格式'
      }else {
        // if(this.name==''||this.name==undefined||this.name==null){
        //   this.errorTextName='请输入您的姓名'
        // }
        // else{
        const abc = /^[\u4e00-\u9fa5]{2,6}$/;
        const reg = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/;
      if((!abc.test(this.name)) && this.name != ''){
        this.errorText=''
        this.errorTextName='请输入您的真实姓名'}
        // this.errorText=''
        else if((!reg.test(this.phoneNo)) && this.phoneNo != '' || (!abc.test(this.name)) && this.name != ''){
          this.errorText='请您输入正确的手机号',
          this.errorTextName='',
          this.phoneNo=''
        // this.errorTextName='请输入您的真实姓名'
      }
      else{
        // this.errorText=''
        // if((!abc.test(this.name)) && this.name != ''){
        //   this.errorTextName='请输入您的真实姓名'
        // }else{
        let data={
          addressInfo:this.addressInfo,
          content:this.content,
          createBy:this.createBy,
          createTime:this.createTime,
          delFlag:this.delFlag,
          email:this.email,
          id:this.id,
          name:this.name,
          phoneNo:this.phoneNo,
          status:this.status,
          updateBy:this.updateBy,
          updateTime:this.updateTime,
        }
        let yy = new Date().getFullYear();
    　　let mm = new Date().getMonth()+1;
    　　let dd = new Date().getDate();
    　　let hh = new Date().getHours();
    　　let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
    　　let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
        let d={

          openid:sessionStorage.getItem("openid"),
          scene:this.isWenXin()?1:2,
          // openid:"o59kX619xOOzJ2Bwd4YqlY5gjO50",
          datetime:yy+'/'+mm+'/'+dd+' '+hh+':'+mf+':'+ss,
          message:'感谢你的咨询，我们将尽快联系你'
        }
        this.$http.postDataForm3(this.$api.noticemessage,d).then(res=>{
           
        })
        this.$http.post(this.$api.qwafcj, data).then(res=>{
          this.errorText='',
          console.log(eval("("+res.data+")"));
          console.log(data);
          // this.$message({
          //       type: 'success',
          //       message: '上传成功!'
          // });
        })
        this.showok=true,
        this.sh=false
     }
      // }
        // }
      }
      },
    }
}
</script>

<style scoped>
.shea{
  position:fixed;
  left:20px;
  bottom:5px;
  z-index:1;
  box-shadow:0px 0px 40px -10px #888888;
}
.sx{
  margin-left:150px;
  background: rgba(255, 192, 203, 0);
  border:2px solid rgba(255, 192, 203, 0);
  color: grey;
  opacity: 1;
  /* margin-top: -100px; */
}
.ssx{
  background: rgba(255, 192, 203, 0);
  border:2px solid rgba(255, 192, 203, 0);
  color: grey;
  opacity: 1;
  /* margin-top: -100px; */
}
.po{
  margin-bottom:10px;
  margin-left:164px;
  height:32px;
  width:64px;
  margin-top:-10px;
  /* z-index: 1; */
  cursor:pointer;
  background:#DA9475;
  color:#FFFFFF;
  border:2px solid rgba(255, 192, 203, 0);
  font-size:12px;
  border-radius: 4px;
  /* margin-left:554px; */
  /* text-align: right; */
}
.delet{
  margin-left:55px;
  /* margin-bottom:10px; */
  /* margin-left:156px; */
  height:42px;
  width:120px;
  margin-top:30px;
  /* z-index: 1; */
  cursor:pointer;
  background:#7595da;
  color:#FFFFFF;
  border:2px solid rgba(255, 192, 203, 0);
  font-size:12px;
  border-radius: 4px;
}
.lyb{
  margin-left:100px;
  /* display: flex; */
  /* margin-left:200px; */
  /* margin-left:390px; */
  /* margin-left:560px; */
  margin-top: -30px;
  font-size: 14px;
}
.mai{
  /* margin-left:554px; */
  width:240px;
  height:46px;
  background:url("../assets/img/book.png") no-repeat;
  background-size:cover;
  /* display: flex; */
}
.ma{
  background:url("../assets/img/book.png") no-repeat;
  background-size:cover;
  width:240px;
  height:36px;
  border-radius: 10px 10px 0px 0px;
  box-shadow:0px 0px 40px -10px #888888;
}
.s{
  margin-left:130px;
  background: rgba(255, 192, 203, 0);
  border:2px solid rgba(255, 192, 203, 0);
  color: grey;
  opacity: 1;
  /* margin-top: -100px; */
}
.ly{
  /* display: flex; */
  /* margin-left:200px; */
  /* margin-left:390px; */
  /* margin-left:560px; */
  margin-top: -30px;
  margin-left:8px;
  padding: 10px;
  font-size: 14px;
}
tr{
  display: block;
  margin: 5px 0;
}
</style>