<template>
    <el-button @click="onBridgeReady" >支付</el-button>
</template>
<script>
export default {
    name: "Wechat",
    inject: ["L_Status"],
    data(){
        return{
            wxpay:{
             
             timeStamp:'',
            nonceStr:'',
            prepay_id:'',
            paySign:''
         }
        }
         
        
    },
    created(){
       
    },
    mounted(){

    },
    methods: {
       
     onBridgeReady(){
       
   window.WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
         "appId":"wxc7be5134e8820ba8",     //公众号ID，由商户传入     
         "timeStamp":this.$route.query.timStamp,         //时间戳，自1970年以来的秒数     
         "nonceStr":this.$route.query.nonce_str, //随机串     
         "package":"prepay_id="+this.$route.query.prepayid,     
         "signType":"MD5",         //微信签名方式：     
         "paySign":this.$route.query.sign //微信签名 
      },
      function(res){
      if(res.err_msg == "get_brand_wcpay_request:ok" ){
      // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
      } 
   });
   this.checkup() 
},
        checkup(){
            
                    if (typeof WeixinJSBridge == "undefined"){
                        if( document.addEventListener ){
                            document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
                        }else if (document.attachEvent){
                            document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady); 
                            document.attachEvent('onWeixinJSBridgeReady',this.onBridgeReady);
                        }
                    }else{
                    this.onBridgeReady();
                    }
                }
                        
        }
}

</script>

