
<template>
  <div>
    <div class="financial-title">
      <img style="display: block" src="" alt="" />
      <div class="title-font">非金融产品</div>
    </div>
    <div class="fin-nav">
      <span class="fin-nav-span" v-for="(item, index) in navLiat" :key="index">
        <div class="fin-nav-name" @click="clickNav(index)">
          {{ item.title
          }}<span :class="chookData == index ? 'chook-span' : ''"></span>
        </div>
        <div
          :class="index % 3 == 2 ? 'com-nav-line nav-none' : 'com-nav-line'"
        ></div>
      </span>
    </div>
    <div class="financial-sty">
      <div class="financial-product">
        <span class="fin-img">#</span>
        <div class="fin-tit">服务类</div>
        <div class="fin-more">更多内容>>></div>
      </div>
      <div class="swiper-sty">
        <swiper :options="swiperOption" ref="mySwiper" class="swiper-size">
          <swiper-slide
            class="service-swiper swiper-slide swiper-container-3d"
            v-for="(banner, index) in bannerList"
            :key="index"
          >
            <div class="swiper-cont">
              <img
                class="swiper-img"
                src="http://www.lemoncome.com/static/img/banner01.png"
                alt=""
              />
              <div class="swiper-right">
                <div>
                  <div class="cont-tit">积分商城</div>
                  <div class="cont-line"></div>
                </div>
                <div class="cont-cont">
                  建立企业商城，搭建专属积分体系，解锁积分营销增强用户粘性，打造积分闭环，让客户保持活跃！各种互动玩法，留存率稳定上涨！
                </div>
                <div class="swiper-but">了解更多</div>
              </div>
            </div>
          </swiper-slide>
          <!-- 分页器 -->
          <!-- <div class="swiper-pagination" slot="pagination"></div> -->
        </swiper>
        <!-- 左右箭头 -->
        <!-- @click="prev" -->
        <div class="swiper-button-prev" slot="button-prev"></div>
        <!-- @click="next" -->
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </div>

    <div class="health-style">
      <div class="financial-sty financial-health">
        <div class="financial-product">
          <span class="fin-img">#</span>
          <div class="fin-tit health-tit">大健康</div>
          <div class="fin-more">更多内容>>></div>
        </div>
      </div>
      <div class="swiper-sty health-swiper">
        <swiper :options="healthOption" ref="mySwiper">
          <swiper-slide
            class="service-swiper swiper-slide swiper-container-3d"
            v-for="(banner, index) in bannerList"
            :key="index"
          >
            <div class="swiper-cont">
              <img
                class="swiper-img swiper-img2"
                src="http://www.lemoncome.com/static/img/banner01.png"
                alt=""
              />
              <div class="swiper-right">
                <div>
                  <div class="cont-tit health-tit">积分商城</div>
                  <div class="cont-line"></div>
                </div>
                <div class="cont-cont health-cont">
                  建立企业商城，搭建专属积分体系，解锁积分营销增强用户粘性，打造积分闭环，让客户保持活跃！各种互动玩法，留存率稳定上涨！
                </div>
                <div class="swiper-but">了解更多</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <!-- 分页器 -->
        <div class="swiper-pagination health-swiper-pagination" slot="pagination"></div>
        <!-- 左右箭头 -->
        <!-- @click="prev" -->
        <!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
        <!-- @click="next" -->
        <!-- <div class="swiper-button-next" slot="button-next"></div> -->
      </div>
    </div>
    <div class="life-style">
      <div class="financial-sty life-sty">
        <div class="financial-product">
          <span class="fin-img">#</span>
          <div class="fin-tit">生活类</div>
          <div class="fin-more">更多内容>>></div>
        </div>
      </div>
      <div class="life-box"> 
        <div class="life-box-left">
          <div class="box-left-cont">
            <div class="left-cont-tit">数字礼券</div>
            <div class="left-cont-cont">携手各商业银行、大型商户和手机厂商合作伙伴推出重振引擎助商惠民计划。跨场景交易送券、社交分享送券，加入场景交融、私域流量等营销新元素</div>
          </div>
        </div>
        <div class="life-box-right">
          <div class="right-top">
            <div class="right-top-left"></div>
            <div class="right-top-right"></div>
          </div>
          <div class="right-bot"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      chookData: 0,
      navLiat: [{ title: "服务类" }, { title: "大健康" }, { title: "生活类" }],
      swiperOption: {
        loop: true, //是否循环轮播
        speed: 1000, //切换速度
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        //自动轮播
        // autoplay: {
        //   delay: 2000,
        //   disableOnInteraction: false,
        // },
        //设置slider容器能够同时显示的slides数量
        slidesPerView: 1,
        //左右切换
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        //分页器
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true, // 允许点击小圆点跳转
        // },
        //设置轮播样式,此处为3d轮播效果
        effect: "slide",
        coverflowEffect: {
          rotate: 30, // 旋转的角度
          stretch: 10, // 拉伸 图片间左右的间距和密集度
          depth: 60, // 深度 切换图片间上下的间距和密集度
          modifier: 2, // 修正值 该值越大前面的效果越明显
          slideShadows: true, // 页面阴影效果
        },
      },
      healthOption:{
        loop: true, //是否循环轮播
        speed: 1000, //切换速度
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        //自动轮播
        // autoplay: {
        //   delay: 2000,
        //   disableOnInteraction: false,
        // },
        //设置slider容器能够同时显示的slides数量
        slidesPerView: 1,
        //左右切换
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        //分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 允许点击小圆点跳转
        },
        //设置轮播样式,此处为3d轮播效果
        effect: "slide",
        coverflowEffect: {
          rotate: 30, // 旋转的角度
          stretch: 10, // 拉伸 图片间左右的间距和密集度
          depth: 60, // 深度 切换图片间上下的间距和密集度
          modifier: 2, // 修正值 该值越大前面的效果越明显
          slideShadows: true, // 页面阴影效果
        },
      },
      bannerList: [
        {
          img: "http://www.lemoncome.com/static/img/banner01.png",
        },
        {
          img: "http://www.lemoncome.com/static/img/banner02.png",
        },
      ],
    };
  },
  created() {
    // this.initSwiper();
  },
  methods: {
    // initSwiper() {},
    clickNav(index) {
      this.chookData = index;
      this.utils.clickNav(index);
    },
  },
};
</script>
<style scoped>
@media screen and (min-width: 750px) and (max-width: 1920px) {
  .financial-title {
    width: 100%;
    height: 300px;
    background: linear-gradient(
      270deg,
      rgba(79, 86, 123, 0.08) 0%,
      #4d5579 100%
    );
  }
  .title-font {
    height: 300px;
    text-align: left;
    margin-left: 480px;
    line-height: 300px;
    font-size: 30px;
    color: #fff;
  }
  .fin-nav {
    width: 1190px;
    height: 130px;
    margin: auto;
    display: flex;
    /* margin-left: 541px; */
    justify-content: center;
    /* padding-left: 541px; */
    background: #fff;
  }
  .fin-nav-span {
    display: flex;
  }
  .fin-nav-name {
    margin-top: 50px;
  }

  .chook-span {
    display: block;
    width: 32px;
    height: 4px;
    margin: auto;
    margin-top: 20px;
    background: #ff9a00;
  }
  .com-nav-line {
    height: 26px;
    width: 1px;
    margin: 50px 100px 0 103px;
    background: #979797;
  }
  .nav-none {
    display: none;
  }
  .financial-sty {
    width: 1190px;
    /* margin: auto; */
    margin: 100px auto auto auto;
    /* padding-top: 100px; */
    /* border: 1px solid; */
  }
  .financial-health {
    padding-top: 100px;
  }
  .financial-product {
    display: flex;
    justify-content: flex-end;
    /* margin-bottom: 24px; */
  }
  .fin-img {
    width: 17px;
    height: 20px;
    /* margin: 0 15px 0 896px; */
  }
  .fin-tit {
    margin-right: 406px;
    font-size: 24px;
    color: #424242;
  }
  .fin-more {
    color: #949494;
    font-size: 20px;
  }

  .swiper-sty {
    width: 1190px;
    height: 435px;
    margin: auto;
    position: relative;
    top: 100px;
  }
  .swiper-size {
    min-width: 750px;
    height: 235px;
  }
  .swiper-container {
    left: 50%;
    transform: translateX(-35%);
  }
  .service-swiper {
    width: 100%;
    height: 335px;
    position: relative;
    overflow: hidden;
  }
  .swiper-cont {
    display: flex;
  }
  .swiper-right {
    position: relative;
    width: 346px;
    margin-left: 54px;
    text-align: left;
  }
  .swiper-img {
    width: 390px;
    height: 235px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 100px;
    width: 50px;
    height: 67px;
    color: #d8d8d8;
  }
  .cont-tit {
    font-size: 16px;
    color: #424242;
  }
  .cont-line {
    width: 50px;
    height: 3px;
    margin-top: 10px;
    background: #ff9a00;
  }
  .cont-cont {
    margin-top: 22px;
    font-size: 14px;
    color: #424242;
  }
  .swiper-but {
    width: 120px;
    height: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: center;
    line-height: 40px;
    /* margin: 82px 0 0 226px; */
    color: #fff;
    background: #ff9a00;
  }
  .health-style {
    width: 100%;
    height: 666px;
    margin-top: 100px;
    background: linear-gradient(180deg, #424242 0%, #7e7e7e 100%);
  }
  .health-tit {
    color: #fff;
  }
  .health-swiper{
    height: 235px;
  }
  .health-swiper-pagination{
    top: 50%;
    transform: translateY(-50%);
  }
  .health-swiper-pagination /deep/ .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin-top: 10px;
    display: block;
    background: #D8D8D8;
  }
  .health-tit,.health-cont{
    color: #fff;
  }
  .life-style{
    margin-bottom: 200px;
  }
  .life-sty{
    margin-top: 150px;
  }
  .life-box{
    width: 1190px;
    height: 500px;
    margin: 81px auto auto;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid; */
  }
  .life-box-left{
    width: 590px;
    height: 500px;
    background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #12326D 100%);
  }
  .box-left-cont{
    width: 490px;
    margin:300px auto auto;
    text-align: end;
    color: #fff;
    /* border: 1px solid; */
  }
  .left-cont-tit{
    font-size: 24px;
    font-weight: bold;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  }
  .left-cont-cont{
    margin-top: 30px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
  }
  .life-box-right{
    width: 565px;
    height: 500px;
  }
  .right-top{
    width: 565px;
    height: 250px;
    display: flex;
    justify-content: space-between;
  }
  .right-top-left , .right-top-right{
    width: 265px;
    height: 250px;
    background: #D8D8D8;
  }
  .right-bot{
    width: 565px;
    height: 215px;
    margin-top: 35px;
    background: #D8D8D8;
  }
}
@media screen and (min-width: 10px) and (max-width: 750px) {
  .financial-title {
    width: 100%;
    height: 260px;
    background: linear-gradient(
      270deg,
      rgba(79, 86, 123, 0.08) 0%,
      #4d5579 100%
    );
  }
  .title-font {
    height: 260px;
    text-align: left;
    margin-left: 40px;
    line-height: 260px;
    font-size: 30px;
    color: #fff;
  }
  .fin-nav {
    width: 100%;
    height: 100px;
    margin: 50px auto 0;
    display: flex;
    /* margin-left: 541px; */
    justify-content: center;
    /* padding-left: 541px; */
    background: #fff;
  }
  .fin-nav-span {
    width: 33%;
    display: flex;
    justify-content: space-around;
  }
  .fin-nav-name {
  }
  .chook-span {
    display: block;
    width: 32px;
    height: 4px;
    margin: auto;
    margin-top: 20px;
    background: #ff9a00;
  }
  .com-nav-line {
    height: 26px;
    width: 1px;
    background: #979797;
  }
  .nav-none {
    display: none;
  }
  .financial-sty {
    width: 100%;
  }
  .financial-health {
    padding-top: 100px;
  }
  .financial-product {
    width: 90%;
    margin: auto;
    display: flex;
  }
  .fin-img {
    width: 17px;
    height: 20px;
  }
  .fin-tit {
    font-size: 20px;
    color: #424242;
  }
  .fin-more {
    margin-left: 30%;
    color: #949494;
    font-size: 18px;
  }

  .swiper-sty {
    width: 90%;
    /* height: 435px; */
    margin: auto;
    position: relative;
    top: 70px;
  }
  .swiper-size {
    max-width: 750px;
    /* height:400px; */
    /* height: 235px; */
  }
  .swiper-container {
    left: 35%;
    transform: translateX(-35%);
  }
  .service-swiper {
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
  }
  .swiper-cont {
    display: flex;
  }
  .swiper-right {
    width: 100%;
    position: absolute;
    top: 265px;
    /* margin-left: 54px;
    text-align: left; */
  }
  .swiper-img {
    width: 100%;
    height: 235px;
    
  }
  .swiper-img2{
    width: 85%;
    height: 235px;
    margin: auto;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 100px;
    width: 50px;
    height: 67px;
    color: #d8d8d8;
  }
  .cont-tit {
    font-size: 16px;
    color: #424242;
  }
  .cont-line {
    width: 50px;
    height: 3px;
    margin-top: 10px;
    background: #ff9a00;
  }
  .cont-cont {
    margin: 22px auto 10px auto;
    font-size: 14px;
    color: #424242;
  }
  .swiper-but {
    width: 120px;
    height: 40px;
    position: absolute;
    /* bottom: 0; */
    right: 0;
    text-align: center;
    line-height: 40px;
    /* margin: 82px 0 0 226px; */
    color: #fff;
    background: #ff9a00;
  }
  .health-style {
    width: 100%;
    height: 666px;
    margin-top: 100px;
    background: linear-gradient(180deg, #424242 0%, #7e7e7e 100%);
  }
  .health-tit {
    color: #fff;
  }
  .health-swiper{
    height: 235px;
  }
  .health-swiper-pagination{
    top: 50%;
    transform: translateY(-50%);
  }
  .health-swiper-pagination /deep/ .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin-top: 10px;
    display: block;
    background: #D8D8D8;
  }
  .health-tit,.health-cont{
    color: #fff;
  }
  .life-style{
    margin-bottom: 350px;
  }
  .life-sty{
    margin-top: 150px;
  }
  .life-box{
    width: 100%;
    height: 500px;
    margin: 81px auto auto;
    display: flex;
    flex-wrap: wrap;
    /* border: 1px solid; */
  }
  .life-box-left{
    width: 100%;
    height: 350px;
    background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #12326D 100%);
  }
  .box-left-cont{
    width: 90%;
    margin:180px auto auto;
    text-align: end;
    color: #fff;
    /* border: 1px solid; */
  }
  .left-cont-tit{
    font-size: 24px;
    font-weight: bold;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  }
  .left-cont-cont{
    margin-top: 30px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
  }
  .life-box-right{
    width: 100%;
    height: 500px;
    margin: 20px auto;
  }
  .right-top{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: space-between;
  }
  .right-top-left , .right-top-right{
    width: 47%;
    height: 200px;
    background: #D8D8D8;
  }
  .right-bot{
    width: 100%;
    height: 215px;
    margin-top: 20px;
    background: #D8D8D8;
  }
}
</style>