// const baseUrl=process.env.VUE_APP_BASE_API
//测试地址
//  const baseUrl='http://dtfcloud.com:8030/gateway-test-api'

//生产地址
const baseUrl='https://dtfcloud.com/gateway-api'


const url={
    jspay:baseUrl+'/center/wx/js',
    loginUrl: baseUrl + '/front/api/wx/login',//登录
    loginSucess: baseUrl+'/front/api/wx/callback',
    getToken: baseUrl + '/front/front/c-account/Info/',//获取token
    getSolution: baseUrl + '/front/portal/solution/findSolution?nums=5',//获取解决方案
    // solutionDetails:  baseUrl + '/portal/solution/findSolutionById',//获取解决方案详情
    getAllProduct:  baseUrl + '/front/portal/product/findAllProduct',//获取所有产品
    productDetails: baseUrl + '/front/portal/product/findProductById',//产品详情
    generalSolution: baseUrl + '/front/portal/product/findList',//通用解决方案二级页面
    scheme:baseUrl + '/front/portal/solution/findList',//解决方案
    schemeDetails:baseUrl + '/front/portal/solution/findSolutionById',//详情
    pay:baseUrl+'/center/wx/submit',
    queryIrder:baseUrl+'/center/product-order',
    mpay:baseUrl+'/center/wx/h5',
    wxlogin:baseUrl+'/center/wx/userinfo',
    // jspay:baseUrl+'/center/wx/JS?',
    qwafcj:baseUrl+'/manager/consult/insert',//留言
    getProdectList:baseUrl+'/front/portal/product/selectproductlist', //金服产品
    postMessage:baseUrl+'/front/front/product-message', //咨询
    noticemessage:baseUrl+'/center/wechat/sendmessage',//咨询通知
}
export default url
