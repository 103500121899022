<template>
  <div>
   <ul>
        <li class="page">
          共{{totalData}}条  
        </li> 
        <li >
          <select class="sele-sty" v-model='sizeCompentPage' @change='handelSizePage(false)'>
            <option v-for="(item,i) in optionData" :key="i"  :value="item.value">{{item.name}}</option>
          </select>
        </li> 
        <li class="pre page" :class="currentCompentPage==1?'isdisabled':''" @click="handelPrePage">&lt;</li> 

        <li  v-for="(page,i) in pageData" :class="currentCompentPage==page.value?'page isactive':'page'" @mouseenter="mouseEnter(page)" @mouseleave="mouseLeave(page)" :key="i" @click='handelCurrentPage(page.value)'>{{page.name}}</li>
        <li class="next page" :class="currentCompentPage==totalPage?'isdisabled':''" @click="handelNextPage">&gt;</li>
      </ul>
  </div>
</template>
<script>
export default {
    props : [
        'totalData','currentPage','sizePage'
    ],
   
  data() {
    return {
        // currentPage:1,
        // sizePage:10,
        // totalData:this.totalSize,
        currentCompentPage:1,
        sizeCompentPage:10,
        totalPage:0,
        pageData:[],
        optionData:[
          {
            name:"10/页",
            value:10
          },
          {
            name:"20/页",
            value:20
          },
          {
            name:"30/页",
            value:30
          },
        ]
    };
  },
    watch:{
      totalData() {
        this.initPage()
        this.handelSizePage(true)
      },
      sizePage() {
        this.initPage()
        this.handelSizePage(true)
      }

    },
    created(){
      this.initPage()
      this.handelSizePage(true)
    },
    methods:{
      handelPageData(isFirastRefresh){//处理分页数据
      this.pageData=[]
      var leftData=[]
      var centerData=[]
      var rightData=[]
        if(this.totalPage>=5){
          if(this.currentCompentPage>=1&&this.currentCompentPage<5){
            leftData=[{name:1,value:1}]
            for(let i=2;i<=6;i++){
              centerData.push({name:i,value:i})
            }
            if(this.totalPage>centerData.length+leftData.length){
              rightData=[{name:"...",value:"right"},{name:this.totalPage,value:this.totalPage}]
            }
          }else if(this.currentCompentPage>=5&&this.currentCompentPage<=parseInt(this.totalPage)-4){
            leftData=[{name:1,value:1},{name:"...",value:"left"}]
            for(let i=this.currentCompentPage-2;i<this.currentCompentPage;i++){
              centerData.push({name:i,value:i})
            }
            for(let i=this.currentCompentPage;i<=this.currentCompentPage+2;i++){
              centerData.push({name:i,value:i})
            }
            rightData=[{name:"...",value:"right"},{name:this.totalPage+"",value:this.totalPage+""}]
          }else{
            leftData=[{name:1+"",value:1+""},{name:"...",value:"left"}]
            for(let i=parseInt(this.totalPage)-5;i<=this.totalPage;i++){
              centerData.push({name:i,value:i})
            }
          }
          this.pageData=leftData.concat(centerData).concat(rightData)
          // leftData=[{name:1,value:1}]
          // rightData=[{name:1,value:1}]
          // this.totalPage.map(x=>{
          //   if(this.currentPage>=1&&this.currentCompentPage>5){
              
          //     if(x<=6){
          //       leftData.push({name:x,value:x})
          //     }else{

          //     }
          //   }
          // })
        }else{
          for(let i=1;i<=this.totalPage;i++){
            this.pageData.push({name:i,value:i})
          }
        }   
        if(!isFirastRefresh){
          this.$emit('change',this.sizeCompentPage,this.currentCompentPage)
        }
      },  
      initPage(){
        this.currentCompentPage=this.currentPage
        this.sizeCompentPage=this.sizePage
      },
      handelSizePage(isFirastRefresh){
        this.currentCompentPage=1
        if(this.sizeCompentPage>=this.totalData){
        this.totalPage=1
        }else{
        this.totalPage=parseInt(this.totalData/this.sizeCompentPage)
        this.totalPage += (this.totalData%this.sizeCompentPage)>=1?1:0
        }
        this.handelPageData(isFirastRefresh)
        // this.$parent.pageChange(this.currentCompentPage)
        // this.$emit('change',this.sizeCompentPage,this.currentCompentPage)
      },
      mouseEnter(val){//移入
        if(val.value=='left'){
          val.name='《'
        }else if(val.value=='right'){
          val.name='》'
        }
      },
      mouseLeave(val){//移出
        if(val.value=='left'){
          val.name='...'
        }else if(val.value=='right'){
          val.name='...'
        }
        
      },
      handelCurrentPage(val){
        if (val=='left') {
          this.currentCompentPage =this.currentCompentPage -2
        }else if(val=='right'){
          this.currentCompentPage =this.currentCompentPage +2
        }else{
        this.currentCompentPage=val
        }
        console.log(`当前页: ${val}`);
        this.handelPageData(false)
      },
      handelPrePage(){//上一页
        this.currentCompentPage = this.currentCompentPage==1?1:--this.currentCompentPage
        this.handelPageData(false)
      },
      handelNextPage(){//下一页
        this.currentCompentPage = this.currentCompentPage==this.totalPage?this.totalPage:++this.currentCompentPage
        this.handelPageData(false)
      }
    }

};
</script>
<style scoped>

ul {
  /* margin: 10px 400px; */
  list-style: none;
}
li {
  float: left;
  /* padding: 0 5px; */
}
.sele-sty{
  padding: 8px 0;
  border-radius: 5px;
  font-size: 16px;
  border: 1px solid #ddd;
}
.next,.pre{
  font-size: 14px;
}
.page {
  /* font-size: 12px; */
  cursor: pointer;
  border: 1px solid #ddd;
  margin: 0 5px 20px;
  padding: 8px 16px;
  background: #fff;
  border-radius: 5px;
}
.isdisabled {
  cursor: no-drop;
}
.isactive {
  background: #ffbc00;
  color: #fff;
}
 /* option:hover{
  background: #ffbc00;
} */

</style>