<template>
 <div>
    <div class="header-bg">
        <div class="header-title">{{name}}</div>
        <div class="header-content">支付二维码</div>
    </div>
  <div id="qrCode" ref="qrCodeDiv"></div>
    
  </div>

</template>
<script>
import QRCode  from "qrcodejs2"
 export default {
    name: "qrCode",
    data() {return{
      code_url:'',
      name :'',
      price :'',
      out_trade_no:'',
    }
      
    },
    created: function () {
      this.init();
     
    },
    mounted(){
     this.queryorder()
    },
    methods: {
        queryorder(){
          let vm=this
   
        var i=  window.setInterval(() => {
            setTimeout(() => {
          
          
            vm.$http.get(vm.$api.queryIrder,{id:vm.out_trade_no}).then(res=>{
                  if(res.code==20000){
                    window.clearInterval(i);
                    console.log('ok');
                    this.$router.push({path:'/index'})
                    
                  }else{
                        console.log("支付失败");
                  }
          });


        }, 0)
}, 10000)    
          
            
         
        
      },
      init(){
        // this.name=this.$route.query.name;
        // this.price=this.$route.query.price;
        let data={
          productname:this.$route.query.name,money:this.$route.query.price,openid:this.$route.query.openid
        }
        this.$http.get(this.$api.pay,data).then((res)=>{
          this.code_url=res.code_url;
          this.out_trade_no=res.out_trade_no;
         this.$nextTick(function () {
        this.bindQRCode();
      })});
      },
      bindQRCode: function () {
        new QRCode(this.$refs.qrCodeDiv, {
          text: this.code_url,
          width: 200,
          height: 200,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
        })
      }
    }
  }
</script>