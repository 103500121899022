<template>
  <div>
    <div class="header-bg">
      <!-- <div class="header">
        <div class="header-ui">数科金云</div>
        <ul class="header-nav">
          <li v-for="(item, i) in navList" :key="i">{{ item.name }}</li>
        </ul>
      </div> -->
      <!-- <Header></Header> -->
      <div class="header-title">一站式金融产业服务平台</div>
      <div class="header-content">
        专注于“金融产品聚合+业务场景撮合+流量数据交易”，实现金融科技赋能产业。
      </div>
      <!-- <button>了解更多</button>
      <button class="register">立即注册</button> -->
    </div>
    <div class="product-content common">
      <div class="common-text">数科产品</div>
      <div class="text-font">FINTECH PRODUCT</div>
      <div class="fintech">
        <span class="fintech1"></span>
      </div>
      <div class="product-style">
        <div :class="index%3==2?'product-content-con product-three-sty':'product-content-con'" v-for="(item,index) in productList" :key="index">
          <div class="product-img">
            <img
              class="img-size"
              :src="item.img"
              alt=""
            />
          </div>
          <div class="content-title" @click="pay(item.name,item.money)">
            <div class="title-tit">{{item.name}}</div>
            <div class="title-content">{{item.digest}}</div>
            <div class="title-price">{{item.price}}</div>
          </div>
        </div>

        <!-- <div class="product-content-con">
          <div class="product-img">
            <img
              class="img-size"
              src="../../assets/img/chanpin1.jpg"
              alt=""
            />
          </div>
          <div class="content-title">
            <div class="title-tit">鲨鲨GO</div>
            <div class="title-content">
              高效组织团队更快、更频繁地交付更稳定的软件
            </div>
            <div class="title-price">低至50元/张</div>
          </div>
        </div>
        <div class="product-content-con">
          <div class="product-img product-text">健康管理</div>
          <div class="content-title">
            <div class="title-tit">新手妈妈年护卡</div>
            <div class="title-content">
              一年期无限次儿科热线，相应快捷，医生24小时内回电，三甲儿科专家坐镇，全年无休7*14小时守护
            </div>
            <div class="title-price">低至100元/张</div>
          </div>
        </div>
        <div class="product-content-con product-three-sty">
          <div class="product-img">
            <img
              class="img-size last-img"
              src="../../assets/img/haitun.png"
              alt=""
            />
          </div>
          <div class="content-title">
            <div class="title-tit">海豚音乐</div>
            <div class="title-content">
              专业团队，100%音乐专业教育背景，与音乐学院达成课题合作、毕业生就业输出全国音乐类学校在读生60万
            </div>
            <div class="title-price">免费领取价值288试听礼包</div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="common service-plan">
      <div class="common-text">通用解决方案</div>
      <div class="text-font">SERVICE PLAN</div>
      <div class="fintech">
        <span class="fintech1"></span>
      </div>
      <div class="plan-content">
        <ul>
          <li
            v-for="(item, index) in solutionList"
            :class="chooseLi == index ? 'plan-li choose-plan-li' : 'plan-li'"
            @click="navData(item, index)"
            :key="index"
          >
            <span style="margin-left: 10px">0{{ index + 1 }}</span>
            <span>{{ item.name }}</span>
            <span style="margin-right: 10px">√</span>
          </li>
        </ul>
        <div class="plan-content-style">
          <div class="plan-right-title">
            {{ navObject.name && navObject.name }}
          </div>
          <div class="plan-title-line"></div>
          <div class="plan-right-content">
            {{ navObject.digest }}
          </div>
          <div class="plan-right-but">
            <div class="plan-right-img">
              <img :src="navObject.img" alt="" />
            </div>
            <button @click="solutionDetails(navObject.id)" class="plan-detail">
              查看详情
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="common product-content">
      <div class="common-text">核心业务</div>
      <div class="text-font">CORE BUSINESS</div>
      <div class="fintech">
        <span class="fintech1"></span>
      </div>
      <div class="core-conten-style">
        <div
          class="core-content"
          v-for="(item, index) in coreList"
          :key="index"
        >
          <div class="core-img">
            <img :src="item.img" alt="" />
          </div>
          <div class="core-bot">
            <div class="core-cont-title">{{ item.title }}</div>
            <div class="core-cont-cont">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="common competence">
      <div class="common-text">核心优势</div>
      <div class="text-font">CORE COMPETENCE</div>
      <div class="fintech">
        <span class="fintech1 competence-fin"></span>
      </div>
      <div class="competence-sty">
        <div class="competence-flex">
          <div
            class="competence-content"
            v-for="(item, index) in competenceList"
            :key="index"
          >
            <div class="com-con-img">
              <img style="width: 100%; height: 100%" :src="item.img" alt="" />
            </div>
            <div class="com-con-con">
              <div class="con-tit">{{ item.title }}</div>
              <div class="con-line"></div>
              <div class="con-content">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
        <!-- <button class="register com-register">立即注册</button> -->
      </div>
    </div>
    <div class="bot-bg-top"></div>
    <div class="bot-bg1"></div>
    <div class="bot-bg2"></div>
    <!-- <foot></foot> -->
    <suggestion></suggestion>
    <div class="foot-code">
      <div class="code-img-sty">
          <div class="code-img">
              <img src="@/assets/img/service.jpg" alt="">
          </div>
          <div class="code-text">数科金云服务号</div>
      </div>
      <div class="code-img-sty">
          <div class="code-img">
              <img src="@/assets/img/subscription.jpg" alt="">
          </div>
          <div class="code-text">数科金云订阅号</div>
      </div>
      <div class="code-img-sty">
          <div class="code-img">
              <img src="@/assets/img/app-code.png" alt="">
          </div>
          <div class="code-text">android app下载</div>
      </div>
    </div>
  </div>
</template>
<script src="http://pv.sohu.com/cityjson?ie=utf-8"></script>
<script>
import suggestion from '@/components/suggestion.vue'
import { Toast } from 'vant';
//import getUserIP from '@/util/utils'
// import foot from '@/components/foot.vue'
// import Header from '@/components/header.vue';
export default {
  data() {
    return {
      chooseLi: 0,
      navObject: {},
      navName: "",
      navDataList: {},
      digest: "",
      ip:'',
      openid:'o59kX619xOOzJ2Bwd4YqlY5gjO50',
      productList:[
        {img:require('../../assets/img/chanpin.png'),name:'鲨鲨GO',digest:'高效组织团队更快、更频繁地交付更稳定的软件',price:'低至50元/张',money:'1'},
        {img:require('../../assets/img/health.png'),name:'新手妈妈年护卡',digest:'一年期无限次儿科热线，相应快捷，医生24小时内回电，三甲儿科专家坐镇，全年无休7*14小时守护',price:'低至100元/张',money:'10000'},
        {img:require('../../assets/img/haitun1.png'),name:'海豚音乐',digest:'专业团队，100%音乐专业教育背景，与音乐学院达成课题合作、毕业生就业输出全国音乐类学校在读生60万',price:'免费领取价值288试听礼包',money:'28800'}
      ],
      coreList: [
        {
          img: require("@/assets/img/core1.jpg"),
          title: "DevOps项目实训",
          content: "高效组织团队更快、更频繁地交付更稳定的软件",
        },
        {
          img: require("@/assets/img/core2.jpg"),
          title: "区块链IT培训",
          content: "凭借区块链独有的应用想法与逻辑，快速获得战略性发展",
        },
        {
          img: require("@/assets/img/core3.jpg"),
          title: "量化交易系统",
          content: "利用技术代替主观判断制定合理策略，避免非理性投资决策",
        },
        {
          img: require("@/assets/img/core4.jpg"),
          title: "金融风控系统",
          content: "采取各种措施和方法，消灭风险事件发生的各种可能性及损失",
        },
        {
          img: require("@/assets/img/core5.jpg"),
          title: "资产核销",
          content:
            "帮助银行资产处置部核销抵押贷款后续产生的不良资产拍品展示，加快银行处置资产核销提高工作效率，缩短资产核销回款周期",
        },
        {
          img: require("@/assets/img/core6.jpg"),
          title: "企业/资产尽调系统",
          content: "对市场风险、技术风险、资金风险等做全面深入的审核",
        },
      ],
      competenceList: [
        {
          img: require("@/assets/img/youshi1.jpg"),
          title: "独立第三方",
          content: "聚合金融平台的承载能力多家控股科技创新公司",
        },
        {
          img: require("@/assets/img/youshi2.jpg"),
          title: "技术背景",
          content: "云计算、大数据、区块链、人工智能技术等成熟场景应用方案",
        },
        {
          img: require("@/assets/img/youshi3.jpg"),
          title: "金融客户资源",
          content: "拥有保险、银行、证券等庞大的金融机构作为主要资源",
        },
      ],
      solutionList: [],
    };
  },
  created() {
    console.log(process.env.VUE_APP_BASE_API, "1111111111");
    
    this.getsolution();
    
    // this.getAllProduct()
    // this.navObject=this.solutionList[0]
  },
  methods: {
    
    solutionDetails(id) {
      console.log(id);
      // let paramsData=msg
      // this.$router.push('/index/solutionDetails/details',params)
      this.$router.push({
        path: "/index/solutionDetails/details",
        query: { id: id },
      });
      // console.log(this.navObject)
    },
    //解决方案点击事件
    navData(item, index) {
      this.chooseLi = index;
      this.navObject = item;
    },
    //获取解决方案列表
    getsolution() {
      this.$http.get(this.$api.getSolution).then((res) => {
        this.solutionList = res.data;
        this.navObject = res.data[0];
        console.log(res);
      });
    },
     onBridgeReady() {
      var vm = this;
      console.log(vm.wxConfigure);
      Toast.clear();
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: "wxc7be5134e8820ba8", //公众号名称，由商户传入
          timeStamp: vm.wxConfigure.timeStamp, //vm.getTimeStamp(2), //时间戳，自1970年以来的秒数1395712654
          nonceStr: vm.wxConfigure.nonce_str, //, //随机串e61463f8efa94090b1f366cccfbbb444
          package: vm.wxConfigure.packageOne, //vm.wxConfigure.packageOne,
          signType: "MD5", //微信签名方式：
          paySign: vm.wxConfigure.paySign //vm.wxConfigure.paySign //微信签名
        },
        function(res) {
          console.log(res);
          Toast.loading({
            duration: 300, // 持续展示 toast
            forbidClick: true,
            message: "支付中..."
          });

          if (res.err_msg == "get_brand_wcpay_request:ok") {
            vm.deleteCoupon(); //核销优惠券  ();//支付成功核销优惠券
            setTimeout(() => {
              Toast.clear();
            }, 1000);
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            // vm.$http
            //     .post(`/prizecustomer/payment/score`, {openid:vm.productObj.oprnid,totalFee:vm.disPrice*100}, true)
            //     .then(res => {
            //       console.log(res);
            //     })
            //     .catch(err => {
            //       console.log(err);
            //     });
            // vm.$router.push({
            //   name: "success",
            //   query: {
            //     name: vm.productObj.productName,
            //     price: vm.productObj.total_fee
            //   }
            // });
          } else {
            
            Toast("支付失败");
            window.location.href='https://dtfcloud.com/platform/'
          }
        }
      );
    },
    isOnBridgeReady() {
      Toast.loading({
        duration: 300, // 持续展示 toast
        forbidClick: true,
        message: "正在跳转支付页面"
      });

      let vm = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        setTimeout(() => {
          Toast.clear();
        }, 1000);

        vm.onBridgeReady();
      }
    },
    pay(name,price){
       if (this._isMobile()) {
         if(this.isWenXin()){
             this.$http.post(this.$api.jspay,{
                openid:sessionStorage.getItem("openid"),
                phone:sessionStorage.getItem("phone"),
                productName:name,
                ip:returnCitySN["cip"],
                money:price,
              }).then(res=>{
                this.wxConfigure=res.data
                this.isOnBridgeReady();
              })
          
           
         }else{
          this.$http.get(this.$api.mpay,{money:price,productname:name,openid:sessionStorage.getItem("openid")}).then(res=>{
          window.location.href=res.data.mweb_url;
      })}
    } else {
     
      this.$router.push({path:'/pay',query:{name:name,price:price,openid:sessionStorage.getItem("openid")}})
    }
     
    },
    
     _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
},
 isWenXin: function () {
      if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
        return true; // 是微信端
      } else {
        return false;
      }

 }
    //获取产品
    // getAllProduct(){
    //   this.$http.get(this.$api.getAllProduct).then(res=>{
    //     console.log(res);
    //     this.productList=res.data
    //     // this.navObject=res.data[0]
    //   })
    // }
  },
  components: {
    suggestion
    // foot,
    // Header
  },
};
</script>
<style scoped>
/* @import '../../assets/css/common.css'; */
@media screen and (min-width: 750px) and (max-width: 1920px) {
  .header-bg {
        width: 100%;
        height: 600px;
        text-align: center;
        /* border: 1px solid; */
        background: url("../../assets/img/bg.png") no-repeat;
        background-size: 100% 100%;
    }
    .header-title {
        color: #fff;
        padding-top: 237px;
        font-size: 60px;
        font-weight: bold;
    }
    .header-content {
        padding-top: 30px;
        font-size: 24px;
        color: #fff;
    }
    .content{
      width: 1190px;
      margin: auto;
    }
  /*  */
  button {
    width: 120px;
    height: 40px;
    margin-top: 0.26667rem;
    background-color: transparent;
    font-size: 0.21333rem;
    color: #fff;
    border: 2px solid #ffffff;
  }
  /* .register {
  margin-left: 10px;
  background: #ff9a00;
  border: 2px solid #ff9a00;
} */
  .common {
    width: 100%;
    height: 680px;
    text-align: center;
    /* padding-top: 100px; */
    /* padding-bottom: 177px; */
    background: #fff;
  }
  .common-text {
    padding-top: 100px;
    font-size: 24px;
    font-weight: bold;
  }
  .product-content {
    /* background-color: #cbcaca; */
    background: url("../../assets/img/bg2.png") no-repeat 100%;
  }
  .product-style {
    width: 1190px;
    margin: auto;
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
  }
  .product-content-con {
    width: 360px;
    height: 380px;
    margin-right: 55px;
    margin-top: 40px;
    background: #ffffff;
  }
  .product-three-sty {
    margin-right: 0;
  }
  .product-img {
    /* width: 360px; */
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #efefef;
  }
  .product-text {
    font-size: 24px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #111111;
    line-height: 31px;
  }
  .img-size {
    width: 100px;
  }
  .last-img{
    width: 100px;
  }
  .text-font {
    padding-top: 5px;
    font-size: 16px;
    color: #424242;
  }
  .fintech {
    width: 300px;
    height: 1px;
    margin-top: 23px;
    background-color: #353535;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .fintech1 {
    width: 80px;
    height: 6px;
    background: #ff9a00;
    display: block;
    margin: auto;
    position: absolute;
    top: -3px;
    left: 50%;
    transform: translateX(-50%);
  }
  .title-tit {
    padding-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #ff9a00;
  }
  .title-content {
    width: 265px;
    margin: auto;
    padding-top: 23px;
    padding-left: 5px;
    text-align: left;
    font-size: 16px;
    color: #424242;
  }
  .title-price {
    padding-top: 25px;
    font-size: 25px;
    font-weight: bold;
    color: #ff9a00;
  }
  .service-plan {
    width: 100%;
    height: 750px;
  }
  .plan-content {
    width: 1190px;
    display: flex;
    /* justify-content: center; */
    /* width: 62%; */
    margin: auto;
    flex-wrap: wrap;
    /* border: 1px solid; */
  }
  .plan-content ul {
    margin-top: 102px;
    box-shadow: 0px 0px 10px 0px rgba(159, 159, 159, 0.5);
  }
  .plan-li {
    width: 390px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    list-style: none;
    line-height: 80px;
    background-color: #fff;
    /* background: linear-gradient(to right, #ffc66e, #ff9a00); */
  }
  .choose-plan-li {
    background: linear-gradient(to right, #ffc66e, #ff9a00);
  }
  .plan-content-style {
    /* width: 54%; */
    margin-top: 128px;
    margin-left: 110px;
  }
  .plan-right-title {
    text-align: left;
    font-size: 22px;
  }
  .plan-title-line {
    width: 28px;
    height: 3px;
    margin-top: 10px;
    background: #ff9a00;
  }
  .plan-right-content {
    /* width: 690px; */
    height: 42px;
    margin-top: 30px;
    text-align: left;
    font-size: 16px;
    line-height: 21px;
  }
  .plan-right-but {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
  }
  .plan-detail {
    width: 120px;
    height: 40px;
    margin-left: 80px;
    background: #ff9a00;
    font-size: 16px;
  }
  .plan-right-img {
    width: 490px;
    height: 228px;
  }
  .plan-right-img img {
    width: 100%;
    height: 100%;
  }
  .core-conten-style {
    display: flex;
    justify-content: center;

    /* justify-content: space-around; */
    /* flex-wrap: wrap; */
  }
  .core-content {
    width: 190px;
    height: 380px;
    margin-left: 10px;
    margin-top: 42px;
  }
  .core-img {
    width: 190px;
    height: 100px;
    background: #efefef;
  }
  .core-img img {
    width: 58px;
    height: 60px;
    margin-top: 29px;
  }
  .core-bot {
    height: 280px;
    background: #ffffff;
  }
  .core-cont-title {
    width: 90%;
    margin: auto;
    padding-top: 25px;
    font-size: 18px;
    color: #424242;
  }
  .core-cont-cont {
    /* width: 90%; */
    margin: auto;
    padding: 25px 15px 63px 15px;
    text-align: left;
    font-size: 16px;
    color: #424242;
  }
  .competence {
    width: 100%;
    height: 547px;
    padding-bottom: 0;
    background: #ff9a00;
    position: relative;
  }
  .competence-fin {
    background: #ffffff;
  }
  .competence-sty {
    width: 1190px;
    height: 450px;
    position: absolute;
    top: 263px;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(169, 169, 169, 0.5);
  }
  .competence-flex {
    display: flex;
    /* justify-content: center; */
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .competence-content {
    width: 194px;
    /* text-align: center; */
    /* display: block; */
    /* border: 1px solid; */
  }
  .com-con-img {
    width: 100px;
    height: 96px;
    margin: 80px auto 0 auto;
  }
  .com-con-con {
  }
  .con-tit {
    margin-top: 51px;
  }
  .con-line {
    width: 50px;
    height: 6px;
    margin: 16px auto 0 auto;
    background: #ff9a00;
  }
  .con-content {
    margin-top: 24px;
  }
  /* .com-register{
  margin: 50px 0 90px 0;
} */
  /* .bot-bg-top{
  width: 100%;
  height: 106px;
  background: #D8D8D8;
} */
  .bot-bg1 {
    /* width: 100%;
    height: 106px;
    background: #f1f2f6; */
  }
  .bot-bg2 {
    width: 100%;
    height: 240px;
    /* margin-top: 106px; */
    background: #ffffff;
    /* border:1px solid; */
  }
  .foot-code{
    width: 100px;
    position: fixed;
    right: 0;
    top: 20%;
  }
  .code-img-sty{
    width: 100px;
    height: 110px;
    text-align: center;
  }
  .code-img{
    width: 77px;
    margin: auto;
  }
  .code-img img{
    width: 100%;
  }
  .code-text{
    font-size: 12px;
  }
}
@media screen and (min-width: 10px) and (max-width: 750px) {
  .header-bg {
      width: 100%;
      height: 300px;
      text-align: center;
      /* border: 1px solid; */
      background: url("../../assets/img/bg.png") no-repeat;
      background-size: 100% 100%;
      /* background-color: antiquewhite; */
    }
    .header-title {
      color: #fff;
      padding-top: 120px;
      font-size: 24px;
      font-weight: bold;
    }
    .header-content {
      padding: 20px;
      font-size: 15px;
      color: #fff;
    }
    .content{
      width: 90%;
      margin: 20px auto;
      word-break:break-all;
    }
  /* 通用解决方案2 */
  .plan-content2{
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 25px;
    font-size: 4vw;
  }
  .plan-content2 ul{
    width: 48%;
    margin-top: 30px;
    box-shadow: 0px 0px 10px 0px rgb(159 159 159 / 50%);
    height: 230px;
  }
  .plan-content-style2{
    width: 48%;
    margin-top: 25px;
  }
  .plan-right-title2{
    text-align: left;
    font-size: 6vw;
  }
  .plan-right-but2{
      margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  .plan-right-img2,.plan-right-img2 img{
    width: 100%;
    height: 87px;
  }
  /* 通用解决方案2 */
  
  button {
    width: 120px;
    height: 40px;
    margin-top: 0.26667rem;
    background-color: transparent;
    font-size: 0.21333rem;
    color: #fff;
    border: 2px solid #ffffff;
  }
  /* .register {
  margin-left: 10px;
  background: #ff9a00;
  border: 2px solid #ff9a00;
} */
  .common {
    width: 100%;
    /* height: 780px; */
    text-align: center;
    /* padding-top: 100px; */
    /* padding-bottom: 177px; */
    background: #fff;
  }
  .common-text {
    padding-top: 50px;
    font-size: 24px;
    font-weight: bold;
  }
  .product-content {
    /* background-color: #cbcaca; */
    background: url("../../assets/img/bg2.png") no-repeat 100%;
    background-size: 100% 100%;
  }
  .product-style {
    width: 100%;
    margin: auto;
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
  }
  .product-content-con {
    width: 360px;
    height: 380px;
    margin-right: 55px;
    margin: 15px auto;
    background: #ffffff;
  }
  .product-three-sty {
    /* margin-right: 0; */
  }
  .product-img {
    /* width: 360px; */
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #efefef;
  }
  .product-text {
    font-size: 24px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #111111;
    line-height: 31px;
  }
  .img-size {
    width: 100px;
  }
  .last-img{
    width: 100px;
  }
  .text-font {
    padding-top: 5px;
    font-size: 16px;
    color: #424242;
  }
  .fintech {
    width: 300px;
    height: 1px;
    margin-top: 23px;
    background-color: #353535;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .fintech1 {
    width: 80px;
    height: 6px;
    background: #ff9a00;
    display: block;
    margin: auto;
    position: absolute;
    top: -3px;
    left: 50%;
    transform: translateX(-50%);
  }
  .title-tit {
    padding-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #ff9a00;
  }
  .title-content {
    width: 265px;
    margin: auto;
    padding-top: 23px;
    padding-left: 5px;
    text-align: left;
    font-size: 16px;
    color: #424242;
  }
  .title-price {
    padding-top: 25px;
    font-size: 25px;
    font-weight: bold;
    color: #ff9a00;
  }
  .service-plan {
    width: 100%;
    /* height: 900px; */
  }
  .plan-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 25px;
    font-size: 13px;
  }
  .plan-content ul {
    width: 50%;
    margin-top: 30px;
    box-shadow: 0px 0px 10px 0px rgb(159 159 159 / 50%);
    height: 230px;
  }
  .plan-li {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    list-style: none;
    line-height: 45px;
    background-color: #fff;
    /* background: linear-gradient(to right, #ffc66e, #ff9a00); */
  }
  .choose-plan-li {
    background: linear-gradient(to right, #ffc66e, #ff9a00);
  }
  .plan-content-style {
    width: 48%;
    margin-top: 25px;
    /* margin-left: 110px; */
  }
  .plan-right-title {
    text-align: left;
    font-weight: bold;
    font-size: 15px;
  }
  .plan-title-line {
    width: 28px;
    height: 3px;
    margin-top: 10px;
    background: #ff9a00;
  }
  .plan-right-content {
    /* width: 690px; */
    /* height: 42px; */
    margin-top: 15px;
    text-align: left;
    font-size: 14px;
    line-height: 21px;
  }
  .plan-right-but {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  .plan-detail {
    width: 120px;
    height: 40px;
    /* margin-left: 80px; */
    margin: 10px 0;
    background: #ff9a00;
    font-size: 16px;
  }
  .plan-right-img,.plan-right-img img{
    width: 100%;
    height: 87px;
  }
  .core-conten-style {
    display: flex;
    /* justify-content: center; */
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .core-content {
    width: 45%;
    height: 380px;
    /* margin-left: 10px; */
    margin: 20px auto 10px;
  }
  .core-img {
    width: 100%;
    height: 100px;
    background: #efefef;
  }
  .core-img img {
    width: 58px;
    height: 60px;
    margin-top: 29px;
  }
  .core-bot {
    height: 280px;
    background: #ffffff;
  }
  .core-cont-title {
    width: 90%;
    margin: auto;
    padding-top: 25px;
    font-size: 18px;
    color: #424242;
  }
  .core-cont-cont {
    /* width: 90%; */
    margin: auto;
    padding: 25px 15px 63px 15px;
    text-align: left;
    font-size: 16px;
    color: #424242;
  }
  .competence {
    width: 100%;
    /* height: 547px; */
    padding-bottom: 0;
    background: #ff9a00;
    /* position: relative; */
  }
  .competence-fin {
    background: #ffffff;
  }
  .competence-sty {
    width: 90%;
    margin: 30px auto 0 auto;
    /* height: 550px;
    position: absolute;
    top: 263px;
    left: 50%;
    transform: translateX(-50%); */
    background: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(169, 169, 169, 0.5);
  }
  .competence-flex {
    display: flex;
    /* justify-content: center; */
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .competence-content {
    width: 194px;
    /* text-align: center; */
    /* display: block; */
    /* border: 1px solid; */
  }
  .com-con-img {
    width: 100px;
    height: 96px;
    margin: 80px auto 0 auto;
  }
  .com-con-con {
  }
  .con-tit {
    margin-top: 51px;
  }
  .con-line {
    width: 50px;
    height: 6px;
    margin: 16px auto 0 auto;
    background: #ff9a00;
  }
  .con-content {
    margin: 24px 0;
  }
  /* .com-register{
  margin: 50px 0 90px 0;
} */
  /* .bot-bg-top{
  width: 100%;
  height: 106px;
  background: #D8D8D8;
} */
  .bot-bg1 {
    width: 100%;
    height: 60px;
    background: #ff9a00;
  }
  .bot-bg2 {
    /* width: 100%; */
    /* height: 7px; */
    /* margin-top: 106px; */
    /* background: #ffffff; */
    /* border:1px solid; */
  }
  .foot-code{
    display: none;
     /* width: 100px;
    position: fixed;
    right: 0;
    top: 20%; */
  }
}
</style>