<template>
  <div>
    <div class="header-bg">
        <div class="header-title">{{name}}</div>
        <div class="header-content" v-html="digest"></div>
    </div>
    <!-- <div>
      <img style="width:100%" :src="img" alt="" />
    </div> -->
    <div class="content" v-html="content"></div>
    <div class="consult" @click.prevent="checkID()">立即咨询</div>
    <MessageDemo :productId="id" v-show="isDemo"></MessageDemo>
  </div>
</template>
<script>
import MessageDemo from './messageDemo.vue';
export default {
  components: { MessageDemo },
  data() {
    return {
      id: this.$route.query.id,
      content:'',
      // img:'',
      digest:'',
      name:'',
      isDemo:false
    };
  },
  created() {
      this.getDetails()
  },
  methods: {
    checkID(){
      this.isDemo=true
    },
    chengDemo(){debugger
      this.isDemo=false
    },
    getDetails() {
    let id =this.id
      this.$http.get(this.$api.productDetails, {id:id}).then((res) => {
        console.log(res);
        if (res.code == 20000) {
          this.content=res.data.content
          // this.img=res.data.img
          this.digest=res.data.digest
          this.name=res.data.name
        //   this.classList = res.data;
        //   this.allProduct = res.data;
          // this.$router.push('/jinFu/financial/details')
        }
      });
    },
  },
};
</script>
<style scoped>
  @import '../../assets/css/common.css';
   @media screen and (min-width: 750px) and (max-width: 1920px) {
    .consult{
      padding: 20px;
      border-radius: 5px;
      color: #fff;
      background: #4D5579;
      width: 180px;
      margin: 0 auto 20px;
      text-align: center;
      font-size: 20px;
      cursor: pointer;
    }
  }

  @media screen and (min-width: 10px) and (max-width: 750px) {
    .content >>> img{
      width: 100%;
    }
    .consult{
      padding: 20px;
      border-radius: 5px;
      color: #fff;
      background: #4D5579;
      width: 180px;
      margin: 0 auto 20px;
      text-align: center;
      font-size: 20px;
      cursor: pointer;
    }
  }


</style>
