import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import 'lib-flexible'
import httpRequest from '@/util/httpInterceptors'
import api from '@/util/api'
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import QRCode from 'qrcodejs2';
import Utils from '@/util/utils.js';
// import Vconsole from 'vconsole';
// const vConsole = new Vconsole();
// Vue.use(vConsole)
import Vant from 'vant';
import { Toast } from 'vant';
import 'vant/lib/index.css';
import 'element-ui/lib/theme-chalk/index.css'
import element from './element/index'
Vue.use(element)
Vue.use(Toast);
Vue.use(Vant);
Vue.prototype.utils=Utils;
console.log(router);
Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.$api = api // ajax请求方法
Vue.config.productionTip = false
Vue.use(vueSwiper)
Vue.prototype.$qrCode = QRCode


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
