import axios from 'axios'
import qs from 'qs'
// import { message } from '@/utils/index';


const http = axios.create({
  timeout: 1000 * 120,
  withCredentials: true,

})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  return config;
}, error => {
  return Promise.reject(error)
})


/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.status == 200) {
    return response.data
  }
}, error => {
  // message({
  //   type: "error",
  //   message:  error.message,
  //   duration: 1000
  // })
  return Promise.reject(error)
})
/**
* post请求data-form表单
*/
http.postDataForm1 = (url, data = {}) => {
  return http({
    url: url,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    cache: false,
    method: "post",
    data: qs.stringify(data, { arrayFormat: 'repeat', indices: false })
  })
}
/**
* post请求data-form表单
*/
http.postDataForm = (url, data = {}) => {
  return http({
    url: url,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    },
    cache: false,
    method: "post",
    data: qs.stringify(data, { arrayFormat: 'repeat', indices: false })
  })
}
http.postDataForm3= (url, data = {}) => {
  return http({
    url: url,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    cache: false,
    method: "post",
    params:data
  })
}
/**
 * post请求json数据
 */
http.postJson = (url, data = {}) => {
  return http({
    url: url,
    cache: false,
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': '*'
    },
    method: "post",
    data: JSON.stringify(data)
  })
}
/**
 * post请求
 */
http.post = (url, data = {}) => {
  return http({
    url: url,
    method: "post",
    data: data
  })
}
 

/**
 * get请求
 */
http.get = (url, data = {}) => {
  return http({
    url: url,
    cache: false,
    method: "get",
    params: data
  })
}

export default http