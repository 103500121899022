<template>
    <div>
        <div class="header-bg">
            <div class="header-title">{{name}}</div>
            <div class="header-content">{{digest}}</div>
        </div>
        <div class="content" v-html="msg"></div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            id: this.$route.query.id,
            msg:'',
            digest:'',
            name:'',
        }
    },
    created(){
        // this.msg=this.$route.query.paramsData
        // console.log(this.msg+'11111');
        this.getData()
    },
    methods:{
        getData(){
            let id=this.id
            this.$http.get(this.$api.schemeDetails, {id:id}).then((res) => {
                console.log(res);
                if (res.code == 20000) {
                this.msg=res.data.content
                this.digest=res.data.digest
                this.name=res.data.name
                //   this.classList = res.data;
                //   this.allProduct = res.data;
                // this.$router.push('/jinFu/financial/details')
                }
            });
        }
    }
    // mounted(){debugger
    //     this.msg=this.$router.msg
    //     console.log(this.msg+'11111');
    // }
}
</script>
<style scoped>
@import '../../assets/css/common.css';
@media screen and (min-width: 10px) and (max-width: 750px) {
    .content >>> img{
        width: 100%;
        
    }
}
</style>