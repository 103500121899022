<template>
    <div>
        <Header></Header>
        <div class="main-content">
            <router-view :key="$route.fullPath"></router-view>
        </div>
        <Foot></Foot>
    </div>
</template>
<script>
import Foot from './foot.vue'
import Header from './header.vue'
export default {
  data(){
      return{

      }
  },
  
  components: { Header,Foot },
  
      
}
</script>
<style scoped>
</style>