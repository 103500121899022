<template>
  <div>
    <div class="header-bg">
      <div class="name">{{name}}</div>
    </div>
    <div class="dataList" v-for="(item,index) in dataList" :key="index">
        <div class="list-sty">
            <div class="list-sty-list">
                <div class="sty-list-img">
                  <img style="width:100%" :src="item.img" alt="">
                </div>
                <div class="list-center">
                    <div class="center-name">{{item.name}}</div>
                    <div class="center-digest">{{item.digest}}</div>
                    <button @click="details(item.id)"  class="list-but list-but2">查看详情</button>
                </div>
            </div>
            <div class="list-but-sty">
                <button @click="details(item.id)"  class="list-but">查看详情</button>
            </div>
        </div>
    </div>
    <div class="loading-more" v-if="pageNum<totalPage" @click="loadingMore()">加载更多</div>
    <div class="common-page">
      <Page v-if="dataList.length>0" class="page-con" :totalData='totalSize' :sizePage='pageSize' :currentPage='pageNum'  @change="pageChange"></Page>
    </div>
  </div>
</template>
<script>
import Page from '@/components/page.vue';
export default {
  components: { Page },
  data() {
    return {
      pageNum:1,//当前页
      pageSize:10,//每页数量
      totalSize:0,//数据总条数
      totalPage:0,//总页数
      dataList:[],
      name:this.$route.query.name,
      type:this.$route.query.type
    };
  },
  created(){
    // this.getProduct()
  },
  mounted(){
    this.getProduct()
  },
  methods:{
    //移动端加载更多
    loadingMore(){
      this.pageNum++
      console.log(this.pageNum);
      this.getProduct(true);
    },
    details(id){
      this.$router.push({
        path: "/sassPlatform/solutionDetails/details",
        query: { id: id },
      });
    },
    getProduct(isMobilePhone){
      let data={
        limit:this.pageSize,
        page:this.pageNum,
        type:this.type 
      }
      this.$http.get(this.$api.scheme,data).then(res=>{
        console.log(res);
        if(res.code==20000){
            // this.dataList=res.data.records
            this.totalSize=res.data.total
            this.totalPage=res.data.pages
            console.log(this.dataList);
            if(isMobilePhone){
              this.dataList = this.dataList.concat(res.data.records);
            }else{
              this.dataList = res.data.records
            }
          // this.$router.push('/jinFu/financial/details')
        }
      })
    },
    pageChange(pageSize,pageNum){
  //  this.pageNum=page
      this.pageSize=pageSize
      this.pageNum=pageNum
      this.getProduct()
   },
  }
};
</script>
<style scoped>
@media screen and (min-width: 750px) and (max-width: 1920px) {
  .header-bg {
    width: 100%;
    height: 600px;
    background: url("../../assets/img/bg.png") no-repeat 100%;
    background-size: 100% 100%;
    /* background-size: 100%; */
    /* background-color: antiquewhite; */
  }
  .name{
      line-height: 79px;
      margin: auto;
      padding-top: 265px;
      align-items: center;
      font-size: 60px;
      text-align: center;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #FFFFFF;
  }
  .common{
      width: 1190px;
      height: 300px;
      margin:100px auto auto auto;
      background: linear-gradient(270deg, rgba(79, 86, 123, 0.08) 0%, #4D5579 100%);
  }
  .financial-title{
      height: 300px;
      font-size: 40px;
      margin-left: 120px;
      line-height: 300px;
      text-align: left;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
  }
  .dataList{
      width: 1190px;
      height: 250px;
      margin: 30px auto;
      background: #f7f7f7;
  }
  .list-sty-list,.list-sty{
      display: flex;
  }
  .sty-list-img{
    width: 250px;
    height: 250px;
  }
  .list-sty{
      justify-content: space-between;
      padding: 40px;
  }
  .list-center{
      margin-left: 30px;
  }
   .center-digest{
    margin-top: 10px;
    font-size: 15px;
    color: #999;
  }
  .center-name{
    font-size: 18px;
    font-weight: bold;
  }
  .list-but-sty{
    margin-top: 70px;
      /* line-height: 150px; */
  }
  .list-but{
      padding: 10px 25px;
      border-radius: 10px;
      /* border: 1px solid #f5f5f5; */
      background: #ff9a00;
      border: none;
      color: #fff;
  }
  .list-but2{
    display: none;
  }
  .common-page{
    width: 1190px;
    margin: auto;
    position: relative;
    height: 150px;
  }
  .page-con{
    position: absolute;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);
  }
   .loading-more{
    display: none;
  }
}
@media screen and (min-width: 10px) and (max-width: 750px) {
  .header-bg {
    width: 100%;
    height: 300px;
    background: url("../../assets/img/bg.png") no-repeat 100%;
    background-size: 100% 100%;
    /* background-size: 100%; */
    /* background-color: antiquewhite; */
  }
  .name{
      line-height: 79px;
      margin: auto;
      padding-top: 140px;
      align-items: center;
      font-size: 35px;
      text-align: center;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #FFFFFF;
  }
  .common{
      width: 1190px;
      height: 300px;
      margin:100px auto auto auto;
      background: linear-gradient(270deg, rgba(79, 86, 123, 0.08) 0%, #4D5579 100%);
  }
  .financial-title{
      height: 300px;
      font-size: 40px;
      margin-left: 120px;
      line-height: 300px;
      text-align: left;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
  }
  .dataList{
      width: 100%;
      margin: 30px auto;
      background: #f7f7f7;
  }
  .list-sty-list,.list-sty{
    width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 5px;
  }
  .sty-list-img{
    width: 50%;
  }
  .list-sty{
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* padding: 40px; */
  }
  .list-center{
    width: 45%;
      /* margin-top: 20px; */
  }
   .center-digest{
    margin-top: 10px;
    padding-right: 10px;
    font-size: 15px;
    color: #999;
  }
  .center-name{
    font-size: 18px;
    font-weight: bold;
  }
  .list-but-sty{
    display: none;
      /* line-height: 250px; */
      /* margin-top: 20px; */
  }
  .list-but{
    margin-top: 30px;
    padding: 10px;
    border-radius: 10px;
    /* border: 1px solid #f5f5f5; */
    background: #ff9a00;
    border: none;
    color: #fff;
  }
  .common-page{
    display: none;
    width: 100%;
    /* margin: auto; */
    position: relative;
    height: 150px;
  }
  .page-con{
    width: 100%;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
  .loading-more{
    display: block;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: red;
    cursor: pointer;
  }
}

</style>