<template>
  <div class="mine">
    <div class="financial-title">
      <img style="display: block" src="" alt="" />
      <div class="title-font">金融产品</div>
    </div>
    <div class="fin-nav">
      <span class="fin-nav-span" v-for="(item, index) in navLiat" :key="index">
        <div class="fin-nav-name" @click="clickNav(index)">
          {{ item.title
          }}<span :class="chookData == index ? 'chook-span' : ''"></span>
        </div>
        <div
          :class="index % 4 == 3 ? 'com-nav-line nav-none' : 'com-nav-line'"
        ></div>
      </span>
    </div>
    <div class="fin-content-bg">
      <div id="nav1" class="financial-sty">
        <div class="financial-product">
          <span class="fin-img">#</span>
          <div class="fin-tit">理财产品</div>
          <div class="fin-more">更多内容>>></div>
        </div>
        <div class="prodeuct-category-sty">
          <div
            :class="
              index % 3 == 2
                ? 'prodeuct-category three-cate'
                : 'prodeuct-category'
            "
            v-for="(item, index) in financialData"
            :key="index"
          >
            <div class="category-log" v-if="item.type == 1">
              <div class="category-log1">HOT</div>
            </div>
            <div
              class="category-log category-log-xin"
              v-else-if="item.type == 2"
            >
              <div class="category-log1">售罄</div>
            </div>
            <div class="category-tit">{{ item.tit }}</div>
            <div class="category-prop">{{ item.prop }}</div>
            <div class="category-prop1">{{ item.prop1 }}</div>
            <div class="category-det">
              <div
                v-for="(item, index) in item.finList"
                :key="index"
                :class="index % 3 == 1 ? 'det-common det-two' : 'det-common'"
              >
                {{ item.title }}
              </div>
              <!-- <div class="det-common det-two">一千元起购</div>
                    <div class="det-common">保险</div> -->
            </div>
            <div class="details-but">{{ item.details }}</div>
          </div>
        </div>
      </div>
      <div id="nav2" class="financial-sty">
        <div class="financial-product">
          <span class="fin-img">#</span>
          <div class="fin-tit">券商产品</div>
          <div class="fin-more">更多内容>>></div>
        </div>
        <div class="prodeuct-category-sty">
          <div
            :class="
              index % 3 == 2
                ? 'prodeuct-category three-cate'
                : 'prodeuct-category'
            "
            v-for="(item, index) in brokerageData"
            :key="index"
          >
            <div class="category-log" v-if="item.type == 1">
              <div class="category-log1">HOT</div>
            </div>
            <div
              class="category-log category-log-xin"
              v-else-if="item.type == 2"
            >
              <div class="category-log1">售罄</div>
            </div>
            <div class="category-tit">{{ item.tit }}</div>
            <div class="category-prop">{{ item.prop }}</div>
            <div class="category-prop1">{{ item.prop1 }}</div>
            <div class="category-det">
              <div
                v-for="(item, index) in item.finList"
                :key="index"
                :class="index % 3 == 1 ? 'det-common det-two' : 'det-common'"
              >
                {{ item.title }}
              </div>
            </div>
            <div class="details-but">{{ item.details }}</div>
          </div>
        </div>
      </div>
      <div id="nav3" class="financial-sty">
        <div class="financial-product">
          <span class="fin-img">#</span>
          <div class="fin-tit">贷款</div>
          <div class="fin-more">更多内容>>></div>
        </div>
        <div class="loan-sty">
          <div class="loan-cont-sty">
            <div class="loan-cont-tit">利率低、放款快</div>
            <div class="loan-cont-sum">0担保、0抵押</div>
            <div class="loan-but">查看详情</div>
          </div>
        </div>
      </div>
      <div id="nav4" class="financial-sty">
        <div class="financial-product">
          <span class="fin-img">#</span>
          <div class="fin-tit">信托</div>
          <div class="fin-more">更多内容>>></div>
        </div>
        <div class="prodeuct-category-sty trust-product">
          <div
            :class="
              index % 3 == 2
                ? 'prodeuct-category three-cate'
                : 'prodeuct-category'
            "
            v-for="(item, index) in brokerageData"
            :key="index"
          >
            <div class="category-log" v-if="item.type == 1">
              <div class="category-log1">HOT</div>
            </div>
            <div
              class="category-log category-log-xin"
              v-else-if="item.type == 2"
            >
              <div class="category-log1">售罄</div>
            </div>
            <div class="category-tit">{{ item.tit }}</div>
            <div class="category-prop">{{ item.prop }}</div>
            <div class="category-prop1">{{ item.prop1 }}</div>
            <div class="category-det">
              <div
                v-for="(item, index) in item.finList"
                :key="index"
                :class="index % 3 == 1 ? 'det-common det-two' : 'det-common'"
              >
                {{ item.title }}
              </div>
            </div>
            <div class="details-but">{{ item.details }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Header from "@/components/header.vue";
export default {
  data() {
    return {
      chookData: 0,
      navLiat: [
        {id:'1', title: "理财" },
        {id:'2',title: "券商产品" },
        {id:'3', title: "贷款" },
        {id:'4', title: "信托" },
      ],
      financialData: [
        {
          type: 1,
          tit: "太平养老颐养66",
          prop: "3.1740%",
          prop1: "近七日年化",
          finList: [
            { title: "中低风险" },
            { title: "一千元起购" },
            { title: "保险" },
          ],
          details: "查看详情",
        },
        {
          type: 2,
          tit: "太平养老颐养66",
          prop: "3.1740%",
          prop1: "近七日年化",
          finList: [
            { title: "中低风险" },
            { title: "一千元起购" },
            { title: "保险" },
          ],
          details: "查看详情",
        },
        {
          type: 0,
          tit: "太平养老颐养66",
          prop: "3.1740%",
          prop1: "近七日年化",
          finList: [
            { title: "中低风险" },
            { title: "一千元起购" },
            { title: "保险" },
          ],
          details: "查看详情",
        },
        {
          type: 0,
          tit: "太平养老颐养66",
          prop: "3.1740%",
          prop1: "近七日年化",
          finList: [
            { title: "中低风险" },
            { title: "一千元起购" },
            { title: "保险" },
          ],
          details: "查看详情",
        },
        {
          type: 0,
          tit: "太平养老颐养66",
          prop: "3.1740%",
          prop1: "近七日年化",
          finList: [
            { title: "中低风险" },
            { title: "一千元起购" },
            { title: "保险" },
          ],
          details: "查看详情",
        },
      ],
      brokerageData: [
        {
          type: 1,
          tit: "太平养老颐养66",
          prop: "3.1740%",
          prop1: "近七日年化",
          finList: [
            { title: "中低风险" },
            { title: "一千元起购" },
            { title: "保险" },
          ],
          details: "查看详情",
        },
        {
          type: 2,
          tit: "太平养老颐养66",
          prop: "3.1740%",
          prop1: "近七日年化",
          finList: [
            { title: "中低风险" },
            { title: "一千元起购" },
            { title: "保险" },
          ],
          details: "查看详情",
        },
        {
          type: 0,
          tit: "太平养老颐养66",
          prop: "3.1740%",
          prop1: "近七日年化",
          finList: [
            { title: "中低风险" },
            { title: "一千元起购" },
            { title: "保险" },
          ],
          details: "查看详情",
        },
      ],
    };
  },

   methods: {
    clickNav(index){
      this.chookData = index;
      this.utils.clickNav(index);
    },

  },
  components: {
    // Header,
  },
};
</script>
<style scoped>
@media screen and (min-width: 750px) and (max-width: 1920px) {
  /* .mine {
        width: 100%;
        height: calc(100vh - 357px);
        overflow: scroll;
        background: #F1F2F6;
    } */
  .financial-title {
    width: 100%;
    height: 300px;
    background: linear-gradient(
      270deg,
      rgba(79, 86, 123, 0.08) 0%,
      #4d5579 100%
    );
  }
  .title-font {
    height: 300px;
    text-align: left;
    margin-left: 480px;
    line-height: 300px;
    font-size: 30px;
    color: #fff;
  }
  .fin-nav {
    width: 1190px;
    height: 130px;
    margin: auto;
    display: flex;
    justify-content: center;
    background: #fff;
  }
  .fin-nav-span {
    display: flex;
  }
  .fin-nav-name {
    margin-top: 50px;
  }
  .chook-span {
    display: block;
    width: 32px;
    height: 4px;
    margin: auto;
    margin-top: 20px;
    background: #ff9a00;
  }
  .com-nav-line {
    height: 26px;
    width: 1px;
    margin: 50px 100px 0 103px;
    background: #979797;
  }
  .nav-none {
    display: none;
  }
  .com-nav-line {
    height: 26px;
    width: 1px;
    margin: 50px 100px 0 103px;
    background: #979797;
  }
  .two-lin {
    margin-left: 100px;
  }
  .fin-content-bg {
    background: #f1f2f6;
  }
  .financial-sty {
    width: 1190px;
    /* margin: auto; */
    margin: auto;
    padding-top: 100px;
    /* border: 1px solid; */
  }
  .financial-product {
    display: flex;
    justify-content: flex-end;
    /* margin-bottom: 24px; */
  }
  .fin-img {
    width: 17px;
    height: 20px;
    /* margin: 0 15px 0 896px; */
  }
  .fin-tit {
    margin-right: 406px;
    font-size: 24px;
    color: #424242;
  }
  .fin-more {
    color: #949494;
    font-size: 20px;
  }
  .prodeuct-category-sty {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .prodeuct-category {
    width: 360px;
    height: 327px;
    margin-top: 75px;
    margin-right: 55px;
    background: #ffffff;
    opacity: 0.9;
    position: relative;
  }
  .three-cate {
    margin-right: 0;
  }
  .category-log {
    width: 100px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    /* background: #FF9A00; */
    background: linear-gradient(-45deg, transparent 22px, #ff9a00 0);
    opacity: 0.9;
    /* border: 1px solid; */
  }
  .category-log-xin {
    background: linear-gradient(-45deg, transparent 22px, #b1b1b1 0);
  }
  .category-log1 {
    width: 70px;
    line-height: 30px;
    font-size: 16px;
    color: #ffffff;
  }
  .category-tit {
    margin-top: 55px;
    font-size: 16px;
    font-weight: bold;
    color: #424242;
  }
  .category-prop {
    margin-top: 20px;
    font-size: 50px;
    font-weight: bold;
    color: #e3454b;
  }
  .category-prop1 {
    margin-top: 5px;
    font-size: 14px;
    color: #949494;
  }
  .category-det {
    margin-top: 21px;
    display: flex;
    justify-content: center;
    color: #e4575c;
  }
  .det-common {
    width: 85px;
    line-height: 23px;
    /* padding: 4px 19px; */
    opacity: 0.9;
    font-size: 12px;
    border: 1px solid #e4575c;
  }
  .det-two {
    margin: 0 14px;
  }
  .details-but {
    width: 280px;
    height: 42px;
    margin: 24px auto 0;
    line-height: 42px;
    font-size: 20px;
    opacity: 0.9;
    background: #fda318;
    color: #ffffff;
  }
  .loan-sty {
    width: 1190px;
    height: 300px;
    margin-top: 100px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.32) 0%,
      #07153c 100%
    );
    opacity: 0.9;
  }
  .loan-cont-sty {
    text-align: left;
    margin-left: 55px;
    color: #fff;
  }
  .loan-cont-tit {
    padding: 65px 0 10px 0;
    font-size: 16px;
    font-weight: bold;
  }
  .loan-cont-sum {
    font-size: 50px;
    font-weight: bold;
    color: #e3454b;
  }
  .loan-but {
    width: 120px;
    height: 40px;
    margin: 20px 0 78px 0;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    font-weight: bold;
    background: #fda318;
    opacity: 0.9;
  }
  .trust-product {
    padding-bottom: 200px;
  }
}
@media screen and (min-width: 10px) and (max: width 750px) {
  body {
    background-color: lightblue;
  }
}
</style>
